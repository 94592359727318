import { useState, useEffect, useRef, forwardRef, useContext } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import { Textinput, PasswordInput,SelectInput } from '../../../Components';
import BigLogo from "../../../assets/img/helpdesk logo2.png";
import Google from "../../../assets/logo/Google.png";
import { post,get } from '../../../Libraries/Fetch';
import { Oval } from 'react-loader-spinner';
import signup from '../../../services/public/signup';
import TextInputScreen from './Components/TextInputScreen';
import PhotoInputScreen from './Components/PhotoInputScreen';
export default function App() {
  let captcha = useRef(null);
  const initialErrorMessage = {
    username: '',
    password: '',
    confirmation: '',
    email: '',
    jekel: '',
    captcha: '',
    fullname: '',
    alamat: '',
    telp: '',
    foto:''
  }
  let [errorMessage, setErrorMessage] = useState(initialErrorMessage);
  let username = useRef(null);
  let fullname = useRef(null);
  let password = useRef(null);
  let jekel= useRef(null);
  let email = useRef(null);
  let confirmation = useRef(null);
  let foto = useRef(null);
  let alamat = useRef(null);
  let telp = useRef(null);
  let captchaElement = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loadingGoogle,setLoadingGoogle]=useState(true);
  const [googleLink,setGoogleLink]=useState('');
  const handleSignup = async (e) => {
    setErrorMessage(initialErrorMessage)
    setLoading(true);
    e.preventDefault();
    let fr=new FormData();
    fr.append('username', username.current.value);
    fr.append('fullname', fullname.current.value);
    fr.append('password', password.current.value);
    fr.append('confirmation', confirmation.current.value);
    fr.append('jekel', jekel.current.value);
    fr.append('email', email.current.value);
    if (foto.current.files[0]!=undefined) {
      fr.append('foto', foto.current.files[0]);
    }
    fr.append('alamat', alamat.current.value);
    fr.append('telp', telp.current.value);
    fr.append('captcha', captcha.current);
    const req=await signup(fr);
    if (req.data.status == "error") {
      if (req.data.message.username) {
        setErrorMessage((n) => { return { ...n, username: req.data.message.username[0] } });
      }
      if (req.data.message.password) {
        setErrorMessage((n) => { return { ...n, password: req.data.message.password[0] } });
      }
      if (req.data.message.confirmation) {
        setErrorMessage((n) => { return { ...n, confirmation: req.data.message.confirmation[0] } });
      }
      if (req.data.message.email) {
        setErrorMessage((n) => { return { ...n, email: req.data.message.email[0] } });
      }
      if (req.data.message.jekel) {
        setErrorMessage((n) => { return { ...n, jekel: req.data.message.jekel[0] } });
      }
      if (req.data.message.fullname) {
        setErrorMessage((n) => { return { ...n, jekel: req.data.message.fullname[0] } });
      }
      if (req.data.message.foto) {
        setErrorMessage((n) => { return { ...n, foto: req.data.message.foto[0] } });
      }
      if (req.data.message.alamat) {
        setErrorMessage((n) => { return { ...n, email: req.data.message.alamat[0] } });
      }
      if (req.data.message.telp) {
        setErrorMessage((n) => { return { ...n, jekel: req.data.message.telp[0] } });
      }
      if (req.data.message.captcha) {
        setErrorMessage((n) => { return { ...n, captcha: req.data.message.captcha[0] } });
      }
      captchaElement.current.reset();
    }
    else {
      alert(req.data.message);
      localStorage.setItem('helpdesk_session_token', req.data.token);
      window.location.reload();
    }
    setLoading(false);
  }
  const checkToken = async () => {
    setLoadingGoogle(true);
    const req = await get('');
    setGoogleLink(req.data.data);
    setLoadingGoogle(false);
  }
  const handleCaptcha = (e) => {
    captcha.current = e;
  }
  useEffect(() => {
    checkToken();
  }, [])
  return (
    <div className='display-flex justify-content-center mt-sm-4 mt-xs-4 mt-md-2 mb-sm-4 mb-xs-4 mb-md-2' style={{ height: '100vh' }}>
      {
        loadingGoogle ?
        <Oval color="#007bff" height={50} width={50} />
        :
        <form onSubmit={handleSignup} className='c-xs-90 c-sm-90 c-md-80 c-lg-80 c-xl-60 border-rad-5 p2' style={{ overflow: 'auto', boxShadow: "1px 1px 10px 3px" }}>
          <div className='display-flex w-100 justify-content-center'>
            <img src={BigLogo} style={{ width: '250px' }} />
          </div>
          <div className='row justify-content-center'>
            <TextInputScreen
              Ref={{
                username,
                fullname,
                password,
                confirmation,
                email,
                jekel,
                alamat,
                telp
              }}
              errorMessage={errorMessage}
            />
            <PhotoInputScreen
              Ref={{
                foto
              }}
              errorMessage={errorMessage.foto}
            />
          </div>
          <ReCAPTCHA
            ref={captchaElement}
            sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITEKEY}
            onChange={handleCaptcha}
          />
          {errorMessage.captcha && <div className='display-flex justify-content-center mt4 mb4'>
            <div className='textcolor-danger'>Recaptcha error</div>
          </div>}
          <div className='display-flex justify-content-center mt4 mb4'>
            <button disabled={loading ? true : false} className='btn display-flex justify-content-center align-items-center btn-primary textcolor-black border-rad-10 w-100'>
              {loading && <Oval
                height={20}
                width={20}
                color="#FFFFFF"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#FFFFFF"
                strokeWidth={2}
                strokeWidthSecondary={2}

              />}
              Sign Up</button>
          </div>
            <a href={googleLink} className='display-flex justify-content-center mb4'>
              <button type="button" className='display-flex justify-content-center align-items-center btn btn-o-secondary textcolor-black border-rad-10 w-100'>
                <img src={Google} />Sign Up with google
              </button>
            </a>
        </form>
      }
    </div>


  )
}