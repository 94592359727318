import { useEffect, useState, useRef } from "react";
import { Oval } from "react-loader-spinner";
import { post, get } from "../../../../Libraries/Fetch";
import { useSelector } from "react-redux";
import { CKEditorElement, SelectInput, Textinput, ImgUpload, PasswordInput } from "../../../../Components";
import { faClipboard,faUser, faEnvelope, faMapMarker, faPhone, faUserCircle, faVenusMars } from "@fortawesome/free-solid-svg-icons";
export default ({ showModal, setShowModal, Ref, saveCallback }) => {
  const token = useSelector(state => state.session.token);
  const [loading, setLoading] = useState(false);
  const [prev, setPrev] = useState("");
  let [alamat, setalamat] = useState([]);
  useEffect(() => {
    setPrev(Ref.foto.current);
  }, [showModal])
  return (
    <div className={showModal ? "modal active" : "modal"}>
      <div className='modal-container'>
        <div className='modal-content'>
          <button onClick={() => { setShowModal(false) }} className='pos-absolute btn-danger' style={{ width: '25px', height: '32px', textAlign: 'center', top: '-10px', right: '-10px' }}>
            X
          </button>
          <div className="modal-header">
            <h1>DATA CUSTOMER</h1>
          </div>
          <form className='modal-body'>
            <Textinput disabled={true} Ref={Ref.username} label="Username" placeholder="username"
              icon={faUser} />
            <Textinput disabled={true} Ref={Ref.nama} label="Nama Lengkap" placeholder="nama lengkap"
              icon={faUserCircle} />
            <Textinput disabled={true} Ref={Ref.alamat} label="Alamat" placeholder="alamat"
              icon={faMapMarker} />
            <Textinput disabled={true} Ref={Ref.telp} label="Nomor Telepon" placeholder="nomor telp (Ex : 08123xxx)"
              icon={faPhone}  />
            <SelectInput disabled={true} Ref={Ref.jekel} label="Jenis Kelamin" icon={faVenusMars}>
              <option value="L">Laki - Laki</option>
              <option value="P">Perempuan</option>
            </SelectInput>
            <Textinput disabled={true} Ref={Ref.email} label="Email" placeholder="email (ex: user@mail.com)"
              icon={faEnvelope}  />
            <div className="w-100 mt2">
              <ImgUpload
                name="gambar"
                Ref={Ref.foto}
                previewOnly={true}
                prev={prev}
                label="Gambar" />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}