export default ({ response }) => {
    let Class = "";
    if (response == "<1 H") {
      Class = "bg-dark-success border-solid--light-success-1";
    }
    else if (response == ">1 H") {
      Class = "bg-dark-danger border-solid--light-danger-1";
    }
    return (
      <div className="display-flex">
        <div style={{ width: '70px',fontSize:'10pt' }} className={"textcolor-white border-rad-5 pl2 pr2 text-center " + Class}>
          {response}
        </div>
      </div>
    )
  }