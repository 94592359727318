export default ()=>{
    return (
      <div className="m2 p2 border-solid-secondary-1 border-rad-10">
        <div className="display-flex justify-content-center w-100">
          <h3 className="textcolor-black">You Have Not Create Any Ticket Yet</h3>
        </div>
        <div className="display-flex justify-content-center w-100">
          <button className="btn-primary border-rad-10 mt2 pl4 pr4 pt2 pb2">
            <span className="textcolor-black">Create Ticket</span>
          </button>
        </div>
  
      </div>
    )
  }