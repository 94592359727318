import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom";
import { setLogout } from "../store";
export default ({ showModal, setShowModal }) => {
  const dispatch=useDispatch();
  const nav=useNavigate();
  const confirmLogout = () => {
    nav("/")
    dispatch(setLogout());
  }
  return (
    <div className={showModal ? "modal active" : "modal"}>
      <div className="modal-container align-items-center">
        <div className="modal-content modal-content c-xs-90 c-sm-90 c-md-40 c-lg-20 c-xl-20">
          <div className="modal-header textcolor-black">
            <h2>Anda Ingin Logout ?</h2>
          </div>
          <div className="modal-body">
            <button onClick={()=>{confirmLogout()}} type="button" className="btn-danger m1 p2 border-rad-10">
              <span className="textcolor-black">Ya</span>
            </button>
            <button onClick={() => { setShowModal(false) }} type="button" className="btn-secondary m1 p2 border-rad-10">
              <span className="textcolor-black">Batal</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}