import { useEffect, useState, useRef } from "react";
import { Oval } from "react-loader-spinner";
import { del, put, get } from "../../../../Libraries/Fetch";
import { useSelector } from "react-redux";
import { CKEditorElement, SelectInput, Textinput, ImgMultiUpload } from "../../../../Components";
import { faClipboard,faSadCry,faFrown,faMeh,faSmile,faLaugh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parse from "html-react-parser";

export default ({ showModal, setShowModal, Ref, saveCallback }) => {
  const token = useSelector(state => state.session.token);
  const ElemenRating = [{ icon: faSadCry, label: "Sangat Buruk" },
  { icon: faFrown, label: "Buruk" },
  { icon: faMeh, label: "Lumayan" },
  { icon: faSmile, label: "Baik" },
  { icon: faLaugh, label: "Memuaskan" }];
  const [loading, setLoading] = useState(false);
  const [data,setData]=useState({
    "ket":"",
    "rating":1
  });
  const initialErrorMessage = {
    ket: ''
  }
  let [errorMessage, setErrorMessage] = useState(initialErrorMessage);
  let [rating, setRating] = useState(0);
  let [prevList, setPrevList] = useState(Ref.foto.current);
  useEffect(() => {
    setPrevList(Ref.oldFoto.current);
    setData({
      ket:Ref.ket.current,
      rating:Ref.rating.current
    })
  }, [showModal])
  return (
    <div className={showModal ? "modal active" : "modal"}>
      <div className='modal-container'>
        <div className='modal-content'>
          <button onClick={() => { setShowModal(false) }} className='pos-absolute btn-danger' style={{ width: '25px', height: '32px', textAlign: 'center', top: '-10px', right: '-10px' }}>
            X
          </button>
          <div className="modal-header">
            <h1>Job Feedback</h1>
          </div>
          <form className='modal-body'>
            <div className="w-100 mt2 display-flex">
              <label>Deskripsi</label>
            </div>
            <div style={{ boxSizing: 'border-box' }} className="textcolor-black bg-light p2 border-solid-dark-secondary-1 w-100 mt2 bg-lignt-secondary border-rad-5">
              {parse(data.ket)}
            </div>
            <div className="w-100 mt2 display-flex">
              <label>Indeks Kepuasan</label>
            </div>
            <div className="w-100 mt2 display-flex">
              {
                Ref.rating.current=="-"?
                  <div className="display-block text-center">
                    <label className="display-block">belum diberi rating</label>
                  </div>
                  :
                  <>
                    {ElemenRating.map((item, index) => (
                      <div className="display-block text-center">
                        <button key={index} type="button" className={Ref.rating.current == index ? "btn-success m2 p1" : "btn-o-success m2 p1"} style={{ fontSize: '24pt' }}>
                          <FontAwesomeIcon icon={item.icon} />
                        </button>
                        <label className="display-block">{item.label}</label>
                      </div>
                    ))
                    }
                  </>
              }

            </div>
            <div className="w-100 mt2">
              <ImgMultiUpload
                name="gambar_f"
                prevList={prevList}
                keyToDisplay="_foto"
                prevOnly={true}
                label="Gambar" />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}