import { useEffect,useState,useRef } from "react";
import {get} from "../../../Libraries/Fetch";
import { useDispatch,useSelector } from "react-redux";
import { Oval } from "react-loader-spinner";
import Options from "./Section/Progress/Components/Options";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircleExclamation, faPlus } from "@fortawesome/free-solid-svg-icons";
import ModalProgress from "./Section/Progress/Components/ModalDetail";
import Progress from "./Section/Progress";
import JobDetail from "./Section/JobDetail/index";
import { useParams } from "react-router-dom";
import { setPageName } from "../../../store";
export default function App() {
  const token=useSelector(state=>state.session.token);
  const dispatch=useDispatch();
  const [showModalProgress,setShowModalProgress]=useState(false);
  const [loading,setLoading]=useState(true);
  const {id_tugas}=useParams();
  const [data,setData]=useState({
    data:{
      "id_tiket": "",
      "tgl_laporan": "" ,
      "tgl_edit": "",
       "topik_problem": "",
       "prioritas": "",
       "selesai_teknisi": "",
       "selesai_customer": "",
       "id_tugas": "",
       "tgl_assign": "",
       "nama": "",
       "nama_customer": "",
       "_tgl_edit": "",
       "_tgl_assign": "",
       "_response": "",
       "_status": ""
    }
  });
  const ModalData={
    progress:{
      tgl:useRef(null),
      ket:useRef(null),
      foto:useRef([])
    },
    add: {
      tgl: useRef(null),
      ket: useRef(null),
      foto: useRef([])
    }
  }
  const page = useRef(1);
  const readAll = async () => {
    setLoading(true);
    let reqStatus = await get("Teknisi/Progress/" + id_tugas, token);
    setData(reqStatus.data.data[0]);

    setLoading(false)
  }
  useEffect(()=>{
    dispatch(setPageName('Progress Tracking'))
    readAll();
  },[id_tugas])
  return (
    <div className="m4">
      {showModalProgress && <ModalProgress
        showModal={showModalProgress}
        setShowModal={setShowModalProgress}
        Ref={ModalData.progress}
      />}
      <>
        {
          loading
            ?
            <div className="row justify-content-center mt2">
              <Oval color="#007bff" height={50} width={50} />
            </div>
            :
            <div className="row mt2 justify-content-space-between">
              <JobDetail data={data}/>
              <Progress status={data._status} id_tugas={id_tugas}/>
            </div>
        }
      </>
    </div>
  )
}