import { useEffect, useState } from "react";
import Camera from "../assets/logo/camera.png";
import Xicon from "../assets/logo/X.png";
export default function (props) {
  const [prev, setPrev] = useState("");
  const handleChange = (e) => {
    if (e.target.files[0]) {
      setPrev(URL.createObjectURL(e.target.files[0]));

    }
  }
  useEffect(() => {
    setPrev(props.prev);
    console.log('imgupload')
  },[props.prev])
  return (
    <div>
      <label>{props.label}</label>
      {props.previewOnly==undefined && <input type="file" ref={props.Ref} onChange={handleChange} name={props.name} style={{ display: 'none' }} id={props.name} />}
      <label for={props.name} className='bg-light-secondary display-flex align-items-center justify-content-center pos-relative' style={{ width: '200px', height: '200px' }}>
        {
          props.previewOnly
          ?
          <>
            <img src={prev} style={{ width: '100%', height: '100%' }} />
          </>
          :
          <>
              {prev && <button type="button" onClick={() => setPrev("")} className='border-solid-dark-danger-3 bg-danger pos-absolute border-rad-5 display-block' style={{ width: '25px', height: '25px', right: '-10px', top: '-10px' }}>
                <img src={Xicon} style={{ width: '100%', height: '100%' }} />
              </button>}
              {
                prev
                  ?
                  <img src={prev} style={{ width: '100%', height: '100%' }} />
                  :
                  <img src={Camera} style={{ width: '50%', height: '50%' }} />}
          </>
        }

      </label>
    </div>
  )
}