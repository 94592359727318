import { useState, useRef,useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock,faEye,faEyeSlash } from "@fortawesome/free-solid-svg-icons";
export default (props) => {
  const [show,setShow]=useState(false);
  const [error, setError] = useState(props.errorMessage);
  let Class = "form-control pt1 pb1 border-rad-5 display-flex ";
  Class += error ? "border-solid-danger-1" : " border-solid-secondary-1";
  useEffect(() => {
    setError(props.errorMessage);
  }, [props.errorMessage]);
  return (
    <div style={props.style} className={props.className}>
      {props.label!=undefined && <div className="display-flex justify-content-space-between align-items-center">
        <div className="mt2 mb2">{props.label}</div>
        {props.secondLabel && props.secondLabel}
      </div>}
      <div className={Class}>
        <div className="m1">
          <FontAwesomeIcon icon={faLock} size="lg" />
        </div>

        <input className="w-100" placeholder={props.placeholder} style={{ border: 'none', outline: 'none' }} ref={props.Ref} onChange={props.onChange} value={props?.value} type={show ? 'text' : 'password'} name={props.name} />
        <button type="button" className="m1 border-none" onClick={()=>{setShow((n)=>!n)}}>
          <FontAwesomeIcon size="lg" icon={show ? faEyeSlash : faEye}/>
        </button>
      </div>
      {error != "" && <div className="textcolor-danger">{error}</div>}
    </div>)
}