import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default({ icon, label, to, selected }) => {
  let Class = "w-100 text-center  pt2 pb2";
  if (selected) Class += " bg-primary";
  return (
    <Link to={to} className={Class}>
      <div><FontAwesomeIcon icon={icon} /></div>
      <div>{label}</div>
    </Link>
  )
}