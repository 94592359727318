import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default ({Ref,icon,errorMessage,loadOptions,label,placeholder,optionsMap}) => {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
    const fetchData = async (inputValue,callback) => {
      if(inputValue==""){
        inputValue="null"
      }
        try {
          setIsLoading(true);
          console.log(callback)
            const req = await loadOptions(inputValue);
            const data = await req.data;
        
            // Map the API response to the format expected by react-select
            // return data.results.map((item) => ({
            // value: item.id,
            // label: item.name,
            // }));
            setIsLoading(false);
            // callback(data.map(optionsMap))
            setOptions(data.map(optionsMap))
            return data.map(optionsMap);
        } catch (error) {
            console.error('Error fetching data:', error);
            return [];
        }
      };
      const handleMenuOpen = async() => {
        const req=await fetchData()
        setOptions(req)
        
      };
  return (
    <div className='w-100'>
      {label && <label>{label}</label>}
      <div className={`d-flex ${errorMessage && "border-danger"} border border-1 rounded-3 w-100 align-items-center custom-input p-1 m-1`}>
      <FontAwesomeIcon size="lg" icon={{icon}} />
            
        <AsyncSelect
          cacheOptions={false}
          styles={{
            container: (provided) => ({
              ...provided,
              width:'100%'})
          }}
          loadOptions={fetchData}
          defaultOptions={options}
          placeholder={placeholder}
          onMenuOpen={handleMenuOpen}
          isLoading={isLoading}
          defaultValue={
            Ref.current?.value?
            {
              "value":Ref.current.value,
              "label":Ref.current.label
            }:
            {}
          }
          onChange={(e)=>{
            Ref.current=e
          }}   
        />
        </div>
        {errorMessage!=""&&
            <div className="text-danger mt-3">
                {errorMessage}
            </div>
            }
    </div>
  );
};


