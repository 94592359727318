import { useEffect, useState, useRef } from "react";
import { Oval } from "react-loader-spinner";
import { post,get } from "../../../../Libraries/Fetch";
import { useSelector } from "react-redux";
import { CKEditorElement,SelectInput, Textinput, ImgUpload, PasswordInput } from "../../../../Components";
import { faClipboard, faEnvelope, faMapMarker, faPhone, faUserCircle,faUser, faVenusMars, faLock } from "@fortawesome/free-solid-svg-icons";
export default ({ showModal, setShowModal, Ref,saveCallback }) => {
  const token = useSelector(state => state.session.token);
  const [loading, setLoading] = useState(false);
  const initialErrorMessage = {
    username: '',
    jekel: '',
    email: '',
    password: '',
    konfirmasi: '',
  }
  let [errorMessage, setErrorMessage] = useState(initialErrorMessage);
  let [alamat,setalamat]=useState([]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(loading){return "";}
    setLoading(true);

    let form = new FormData();
    form.append("username", Ref.username.current.value);
    form.append("jekel", Ref.jekel.current.value);
    form.append("email", Ref.email.current.value);
    form.append("password", Ref.password.current.value);
    form.append("konfirmasi", Ref.konfirmasi.current.value);
    if (Ref.foto.current.files[0] != undefined){
      form.append("foto", Ref.foto.current.files[0]);
    }
    let req = await post('Admin/Admin', form, token);
    if (req.data.status == "success") {
      setLoading(false);
      alert(req.data.message)
      setShowModal(false);
      saveCallback();

    }
    else if (req.data.status == "error") {
      setLoading(false);
      alert(req.data.message);

      if(req.data.data!==undefined){
        if (req.data.data.username) {
          setErrorMessage((n) => { return { ...n, username: req.data.data.username[0] } });
        }
        if (req.data.data.email) {
          setErrorMessage((n) => { return { ...n, email: req.data.data.email[0] } });
        }
        if (req.data.data.password) {
          setErrorMessage((n) => { return { ...n, password: req.data.data.password[0] } });
        }
        if (req.data.data.konfirmasi) {
          setErrorMessage((n) => { return { ...n, konfirmasi: req.data.data.konfirmasi[0] } });
        }
      }
    }
  }
  return (
    <div className={showModal ? "modal active" : "modal"}>
      <div className='modal-container'>
        <div className='modal-content'>
          <button onClick={() => { setShowModal(false) }} className='pos-absolute btn-danger' style={{ width: '25px', height: '32px', textAlign: 'center', top: '-10px', right: '-10px' }}>
            X
          </button>
          <div className="modal-header">
            <h1>ADD ADMIN</h1>
          </div>
          <form onSubmit={handleSubmit} className='modal-body'>
            <Textinput Ref={Ref.username} label="Username" placeholder="username"
              icon={faUser} errorMessage={errorMessage.username} />
            <SelectInput Ref={Ref.jekel} label="Jenis Kelamin" icon={faVenusMars}>
              <option value="L">Laki - Laki</option>
              <option value="P">Perempuan</option>
            </SelectInput>
            <Textinput Ref={Ref.email} label="Email" placeholder="email (ex: user@mail.com)"
              icon={faEnvelope} errorMessage={errorMessage.email} />
            <PasswordInput Ref={Ref.password} label="Password" placeholder="password"
              icon={faLock} errorMessage={errorMessage.password} />
            <PasswordInput Ref={Ref.konfirmasi} label="Konfirmasi Password" placeholder="konfirmasi password"
              icon={faLock} errorMessage={errorMessage.konfirmasi} />
            <div className="w-100 mt2">
              <ImgUpload
                name="gambar"
                Ref={Ref.foto}
                label="Gambar" />
            </div>
            <div className="w-100 dislay-flex mt2">
              <button disabled={false} className="btn-o-primary align-items-center border-rad-5 display-flex textcolor-black p2 m1">
                {loading && <Oval height={25} width={25} />}
                Simpan
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}