import "../../assets/css/custom.scss"
import { useDispatch, useSelector } from "react-redux";
import { get } from "../../Libraries/Fetch";
import { useEffect, useRef, useState } from "react";
import { setLogout, setPageName } from "../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown,faCaretLeft, faH, faHistory, faHome, faNewspaper, faPrint, faUser, faUserCheck, faWrench } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";

import { ModalLogout,HistoryItem,MenuItem,MenuItemMobile } from "../../Components";
import DesktopFrame from "./Components/DesktopFrame";
import MobileFrame from "./Components/MobileFrame";
export default () => {
  const lebar=window.innerWidth;
  const userData = useSelector(state => state.session.userData);
  const [userSetting,setUserSetting]=useState(false);
  const [historyList, setHistoryList] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector(state => state.session.token);
  const page = useSelector(state => state.session.page);
  const history=useSelector(state=>state.session.history);


  useEffect(()=>{
    console.log(window.innerWidth);
  },[])
  return (
    <>
      <ModalLogout showModal={showModal} setShowModal={setShowModal}/>
      {lebar>992
      ?
        <DesktopFrame userData={userData} setHistoryList={setHistoryList} setUserSetting={setUserSetting} userSetting={userSetting} setShowModal={setShowModal} history={history} page={page} />
        :
        <MobileFrame userData={userData} historyList={historyList} setHistoryList={setHistoryList} setUserSetting={setUserSetting} userSetting={userSetting} setShowModal={setShowModal} history={history} page={page} />
      }
    </>

  )
}