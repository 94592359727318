import { useEffect, useState, useRef } from "react";
import { Oval } from "react-loader-spinner";
import { post, get } from "../../../../../../Libraries/Fetch";
import { useSelector } from "react-redux";
import { CKEditorElement, SelectInput, Textinput, ImgMultiUpload } from "../../../../../../Components";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
export default ({ showModal, setShowModal, Ref, saveCallback }) => {
  const token = useSelector(state => state.session.token);
  const [loading, setLoading] = useState(false);
  const initialErrorMessage = {
    topik: '',
    ket: '',
  }
  let [errorMessage, setErrorMessage] = useState(initialErrorMessage);
  let [prevList, setPrevList] = useState([]);
  let [prioritas, setPrioritas] = useState([]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) { return ""; }
    setLoading(true);
    let form = new FormData();
    form.append("id_tugas", Ref.id_tugas.current);
    form.append("topik", Ref.topik.current.value);
    form.append("ket", Ref.ket.current.data.get());
    Ref.foto.current.forEach((item, index) => {
      form.append("foto[]", item);
    })
    let req = await post('Teknisi/Progress', form, token);
    if (req.data.status == "success") {
      setLoading(false);
      alert(req.data.message)
      setShowModal(false);
      setPrevList([]);
      Ref.foto.current = [];
      if(saveCallback){saveCallback();}
    }
    else if (req.data.status == "error") {
      setLoading(false);
      alert(req.data.message);
      if (req.data.data.ket) {
        setErrorMessage((n) => { return { ...n, ket: req.data.data.ket[0] } });
      }
      if (req.data.data.topik) {
        setErrorMessage((n) => { return { ...n, topik: req.data.data.topik[0] } });
      }
    }
  }
  return (
    <div className={showModal ? "modal active" : "modal"}>
      <div className='modal-container'>
        <div className='modal-content'>
          <button onClick={() => { setShowModal(false) }} className='pos-absolute btn-danger' style={{ width: '25px', height: '32px', textAlign: 'center', top: '-10px', right: '-10px' }}>
            X
          </button>
          <div className="modal-header">
            <h1>ADD PROSES</h1>
          </div>
          <form onSubmit={handleSubmit} className='modal-body'>
            <Textinput Ref={Ref.topik} label="Topik" placeholder="topik"
              icon={faClipboard} errorMessage={errorMessage.topik} />
            <CKEditorElement
              label="Deskripsi"
              callBack={(editor) => { Ref.ket.current = editor }} />
            <div className="w-100 mt2">
              <ImgMultiUpload
                name="gambar"
                prevList={prevList}
                closeCallback={(index) => {
                  Ref.foto.current = Ref.foto.current.filter((item) => Ref.foto.current.indexOf(item) != index)
                  setPrevList((n) => {
                    let nilaiLama = n;
                    return n.filter((item) => n.indexOf(item) != index);
                  })
                }}
                addCallback={(file) => {
                  setPrevList((n) => {
                    return [...n, URL.createObjectURL(file)];
                  });
                  Ref.foto.current.push(file);

                }}
                label="Gambar" />
            </div>
            <div className="w-100 dislay-flex mt2">
              <button disabled={false} className="btn-o-primary align-items-center border-rad-5 display-flex textcolor-black p2 m1">
                {loading && <Oval height={25} width={25} />}
                Simpan
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}