export default ({priority})=>{
  let Class="";
  if(priority=="Low"){
    Class="bg-dark-success border-solid--light-success-1";
  }
  else if (priority == "Medium") {
    Class = "bg-dark-warning border-solid--light-warning-1";
  }
  else if (priority == "Urgent") {
    Class = "bg-dark-danger border-solid--light-danger-1";
  }
  return (
    <div className="display-flex justify-content-center">
      <div style={{ width: '70px',fontSize:'10pt' }} className={"textcolor-white border-rad-5 pl2 pr2 text-center " + Class}>
        {priority}
      </div>
    </div>
  )
}