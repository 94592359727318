import { useEffect, useState,useRef } from "react";
import {get} from "../../../Libraries/Fetch";
import { useSelector,useDispatch } from "react-redux";
import { Oval } from "react-loader-spinner";
import icon_ticket from "../../../assets/logo/icon_ticket.png";
import icon_ticket_complete from "../../../assets/logo/icon_ticket_complete.png";
import icon_ticket_failed from "../../../assets/logo/icon_ticket_failed.png";
import icon_ticket_assigned from "../../../assets/logo/icon_ticket_assigned.png";
import NoTicket from "./Components/NoTicket";
import Card from "../../../Components/Card"
import RatingCard from "./Components/RatingCard";
import { setPageName } from "../../../store";
import TextInput from "../../../Components/TextInput";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
export default function App() {
  const token=useSelector(state=>state.session.token);
  const dispatch=useDispatch();
  const [data,setData]=useState({
    'jml_tugas':0,
    'jml_complete':0,
    'jml_failed':0,
  });
  const Ref={
    dari:useRef(null),
    sampai:useRef(null)
  }
  const [loading,setLoading]=useState(true);
  const readData=async()=>{
    setLoading(true);
    let req=await get("Teknisi",token)
    setData(req.data.data);
    setLoading(false);
  }
  const filterData=async()=>{
    setLoading(true);
    let req=await get("Teknisi?dari="+Ref.dari.current.value+"&sampai="+Ref.sampai.current.value,token)
    setData(req.data.data);
    setLoading(false);
  }
  useEffect(()=>{
    dispatch(setPageName("Home"));
    readData();
  },[])
  return (
    <>
      {
        loading
        ?
        <div className="w-100 display-flex justify-content-center align-items-center">
            <Oval color="#007bff" height={50} width={50} />
        </div>
        :
        <>
        {
          data.jml_tugas>0
          ?
          <>
          <div className="row justify-content-space-arround m4">              
          <div className="w-90">
            <label>Filter Tanggal</label>
          </div>
          <div className="w-90 display-flex align-items-center justify-content-space-between">
            <TextInput className="w-40" Ref={Ref.dari} placeholder="dari" type="date" icon={faCalendar} />
            <label>Sampai</label>
            <TextInput className="w-40" Ref={Ref.sampai} placeholder="sampai" type="date" icon={faCalendar} />
          </div>
          <div className="w-90 display-flex">
            <button onClick={()=>{filterData()}} disabled={false} className="btn-o-primary align-items-center border-rad-5 display-flex textcolor-black p2 m1">                  
              Filter
            </button>
            <button onClick={()=>{readData()}} disabled={false} className="btn-o-success align-items-center border-rad-5 display-flex textcolor-black p2 m1">                  
              Reset
            </button>
          </div>
          
        </div>
            <div className="row justify-content-space-arround m4">
              <RatingCard
                count={data.rating}
              />
              <Card
                count={data.jml_complete}
                label="Complete"
                icon={icon_ticket_complete}
                btn_color="btn-primary"
              />
            </div>
            <div className="row justify-content-space-arround m4">
              <Card
                count={data.jml_tugas}
                label="Assigned"
                icon={icon_ticket_assigned}
                btn_color="btn-purple"
              />
              <Card
                count={data.jml_failed}
                label="Failed"
                icon={icon_ticket_failed}
                btn_color="btn-danger"
              />
            </div>
          </>
          :
          <NoTicket/>

        }
        </>

      }
    </>

  )
}