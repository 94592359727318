import { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function (props) {
  const [error, setError] = useState(props.errorMessage);
  let Class = "form-control pt1 pb1 border-rad-5 display-flex ";
  Class += error ? "border-solid-danger-1" : " border-solid-secondary-1";
  useEffect(() => {
    setError(props.errorMessage);
  }, [props.errorMessage]);
  return (
    <div style={props.style} className={props.className}>
      {props.label!=undefined && <div className="display-flex justify-content-space-between">
        <div className="mt2 mb2">{props.label}</div>
        {props.secondLabel && props.secondLabel}
      </div>}
      <div className={Class}>
        {props.icon &&
          <div className="m1">
            <FontAwesomeIcon size="lg" icon={props.icon} />
          </div>
        }
        {
          props.disabled == true
          ?
            <select className="w-100" style={{ border: 'none', outline: 'none', background: 'transparent' }} ref={props.Ref} disabled name={props.name} >
              {props.children}
            </select>
          :
            <select className="w-100" style={{ border: 'none', outline: 'none', background: 'transparent' }} ref={props.Ref} onChange={props.onChange} name={props.name} >
              {props.children}
            </select>
        }
      </div>
      {error != "" && <div className="textcolor-danger">{error}</div>}
    </div>)
}