import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
export default function (props){
  return (
    <div className="App">
      <label>{props.label}</label>
      <CKEditor
        editor={Editor}
        onReady={props.callBack}
      />
    </div>
  )
}