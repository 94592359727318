import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLaugh,faFrown, faMeh,faSmile, faSadCry,faMehBlank } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
export default ({ count }) => {
  let icon=faSadCry;
  let btn_color="btn-danger";
  let ratingLabel="Terrible";
  if(count>4&&count<=5){
    icon=faLaugh;
    btn_color="btn-success";
    ratingLabel="Awesome"
  }
  else if (count > 3 && count <= 4) {
    icon = faSmile;
    btn_color = "btn-primary";
    ratingLabel = "Good";
  }
  else if (count > 2 && count <= 3) {
    icon = faMeh;
    btn_color = "btn-secondary";
    ratingLabel = "Medium";
  }
  else if (count > 1 && count <= 2) {
    icon = faFrown;
    btn_color = "btn-warning";
    ratingLabel = "Bad";
  }
  else if (count = "-") {
    icon = faMehBlank;
    btn_color = "btn-secondary";
    ratingLabel = "No Rating Yet";
  }
  return (
    <div className="mb-xs-4 mb-sm-4 mb-md-4 mb-lg-0 c-xs-90 c-sm-90 c-md-45 c-lg-35 c-xl-30 border-solid-secondary-1 border-rad-10 p2">
      <div className="row align-items-center justify-content-center">
        <div className="w-50">
          <h1 className="textcolor-black text-center">{count}</h1>
          <div className="text-center" style={{ fontSize: '16pt' }}>RATING</div>
        </div>
        <div className="w-50">
          <FontAwesomeIcon icon={icon} style={{ width: '73px', height: '70px' }} />
        </div>
      </div>
      <div className="row">
        <button type="button" className={btn_color + " w-100 m2 border-rad-10"} >
          <span className="textcolor-black">{ratingLabel}</span>
        </button>
      </div>
    </div>
  )
}