import { useState } from "react";
import { Oval } from "react-loader-spinner";
import { del } from "../../../../Libraries/Fetch";
import { useSelector,useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots,faCheck,faXmark,faPencilSquare,faTrash,faHistory,faCircleQuestion, faWrench, faThumbsDown, faX } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { setHistory } from "../../../../store";
export default ({
  item,
  handleDetail,
  handleModalAssign,
  handleModalCancel,
  deleteCallback,
  setConfirm,
  setDelete,
  readFeedback,
  showComplain
}) => {
  const nav=useNavigate();
  const dispatch=useDispatch();
  let token = useSelector(state => state.session.token);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [loadingFeedback, setLoadingFeedback] = useState(false);
  const [loadingDel, setLoadingDel] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  return (
    <>
      {item._jml_complain > 0 &&
        <div className="row justify-content-center">
          <button
            style={{ width: '25px' }}
            onClick={async () => {
              nav("/Complain/" + item.id_tiket)
            }} type="button" className="m1 btn-o-danger textcolor-black border-rad-5 display-flex align-items-center">
            <FontAwesomeIcon icon={faThumbsDown} />

          </button>
        </div>}
      {item._status == "NA" && <div className="row justify-content-center">
        <button
          style={{ width: '25px' }}
          onClick={async () => {
            handleModalAssign(item.id_tiket);
          }} type="button" className="m1 btn-o-secondary textcolor-black border-rad-5 display-flex align-items-center">
          {loadingEdit ? <Oval color="black" height={15} width={15} />
            :
            <FontAwesomeIcon icon={faWrench} />
          }

        </button>

      </div>}
      {(item._status == "NA" || item._status == "A" || item._status == "IP")
      &&
      <div className="row justify-content-center">
        <button
          style={{ width: '25px' }}
          onClick={async () => {
            handleModalCancel(item.id_tiket);
          }} type="button" className="m1 btn-o-secondary textcolor-black border-rad-5 display-flex justify-content-center align-items-center">
          {loadingEdit ? <Oval color="black" height={15} width={15} />
            :
            <FontAwesomeIcon icon={faX} />
          }

        </button>

      </div>}
      {item._status == "C" &&
      <div className="row justify-content-center">
        <button
          style={{ width: '25px' }}
            onClick={async () => {
              setLoadingFeedback(true);
              await readFeedback(item.id_tiket);
              setLoadingFeedback(false)
            }} type="button" className="m1 btn-o-secondary textcolor-black border-rad-5 display-flex align-items-center">
          {loadingFeedback ?
            <Oval color="black" height={15} width={15} />
            :
            <FontAwesomeIcon icon={faCommentDots} />
          }
        </button>
      </div>}
      <div className="row justify-content-center">
        <button style={{ width: '25px' }}
         onClick={async () => {
          dispatch(setHistory({id:item.id_tiket,label:item.infrastruktur.topik_masalah.nama}));
          nav("/Progress/" + item.id_tiket)
        }} type="button" className="m1 btn-o-secondary textcolor-black border-rad-5 display-flex align-items-center">
          <FontAwesomeIcon icon={faHistory} />

        </button>
        <button
          style={{width:'25px'}}
          onClick={async () => {
            await handleDetail(item.id_tiket);
          }}
          type="button" className="m1 btn-o-secondary textcolor-black border-rad-5">
          {loadingDetail ?
            <Oval color="black" height={15} width={15} />
            :
            <FontAwesomeIcon icon={faCircleQuestion} />
          }
        </button>
        <button
          style={{ width: '25px' }}
          onClick={async () => {
            setDelete(item.id_tiket)
          }}
          type="button" className="m1 btn-o-danger textcolor-black border-rad-5">
          {loadingDel ?
            <Oval color="black" height={15} width={15} />
            :
            <FontAwesomeIcon icon={faTrash} />
          }
        </button>
      </div>
    </>
  )
}