import { useState } from "react";
import { Oval } from "react-loader-spinner";
import { del } from "../../../../Libraries/Fetch";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots,faCheck,faXmark,faPencilSquare,faTrash,faHistory,faCircleQuestion, faKey, faQuestion, faComputer } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
export default ({
  item,
  readOne,
  setDelete,

}) => {
  const nav=useNavigate();
  let token = useSelector(state => state.session.token);
  const [loadingEditData, setLoadingEditData] = useState(false);
  const [loadingDel, setLoadingDel] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  return (
    <div className="row">
      <button
        style={{ width: '25px' }}
        onClick={async () => {
          setLoadingEditData(true);
          await readOne(item.id_infrastruktur);
          setLoadingEditData(false)
        }} type="button" className="m1 btn-o-secondary textcolor-black border-rad-5 display-flex align-items-center">
        {loadingEditData ? <Oval color="black" height={15} width={15} />
          :
          <FontAwesomeIcon icon={faPencilSquare} />
        }

      </button>
      <button
        style={{ width: '25px' }}
        onClick={async () => {
          setDelete(item.id_infrastruktur)
        }}
        type="button" className="m1 btn-o-danger textcolor-black border-rad-5">
        {loadingDel ?
          <Oval color="black" height={15} width={15} />
          :
          <FontAwesomeIcon icon={faTrash} />
        }
      </button>
    </div>
  )
}