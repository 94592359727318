import { CustomerRoutes } from "../../Routes";
import { useDispatch,useSelector } from "react-redux";
import {get} from "../../Libraries/Fetch";
import { Link, NavLink, Route, Router, Routes, redirect, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState,useRef } from "react";
import {setLogout,setPageName} from "../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faH, faHome,faCaretDown,faCaretLeft,faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { faClipboard,faHistory } from "@fortawesome/free-solid-svg-icons";
import { ModalLogout,MenuItem,MenuItemMobile,HistoryItem } from "../../Components";

const DesktopFrame = ({userData,setShowModal,history,page}) => {
  const frameRef = useRef(null);
  const menuRef = useRef(null);
  const minimize = useRef(false);
  const userSettingRef = useRef(null);
  const pageTitleRef = useRef(null);
  const toggleMinimizeRef = useRef(false);
  const toggleMinimize = () => {
    minimize.current = !minimize.current;
    toggleMinimizeRef.current.style.transform = minimize.current ? 'rotateZ(180deg)' : 'rotateZ(0deg)';
    menuRef.current.style.height = minimize.current ? '600px' : '500px';
    frameRef.current.style.height = minimize.current ? '600px' : '500px';
    if (minimize.current) {
      let menuItemList = Array.from(menuRef.current.querySelectorAll('.menu-label'));
      menuItemList.forEach((item, index) => {
        item.classList.add('display-none');
      });
      frameRef.current.classList.remove("c-lg-75");
      frameRef.current.classList.remove("c-xl-75");
      frameRef.current.classList.add("c-lg-85");
      frameRef.current.classList.add("c-xl-90");

      menuRef.current.classList.remove("c-lg-20");
      menuRef.current.classList.remove("c-xl-20");
      menuRef.current.classList.add("c-lg-8");
      menuRef.current.classList.add("c-xl-6");

      pageTitleRef.current.classList.remove("c-lg-75");
      pageTitleRef.current.classList.remove("c-xl-75");
      pageTitleRef.current.classList.add("c-lg-85");
      pageTitleRef.current.classList.add("c-xl-90");

      userSettingRef.current.classList.remove("c-lg-20");
      userSettingRef.current.classList.remove("c-xl-20");
      userSettingRef.current.classList.add("c-lg-8");
      userSettingRef.current.classList.add("c-xl-6");

      document.querySelector('.username-label').classList.add('display-none');
      document.querySelector('.user-control').classList.add('display-none');
    } else {
      let menuItemList = Array.from(menuRef.current.querySelectorAll('.menu-label'));
      menuItemList.forEach((item, index) => {
        item.classList.remove('display-none');
      });
      frameRef.current.classList.add("c-lg-75");
      frameRef.current.classList.add("c-xl-75");
      frameRef.current.classList.remove("c-lg-85");
      frameRef.current.classList.remove("c-xl-90");

      menuRef.current.classList.add("c-lg-20");
      menuRef.current.classList.add("c-xl-20");
      menuRef.current.classList.remove("c-lg-8");
      menuRef.current.classList.remove("c-xl-6");

      pageTitleRef.current.classList.add("c-lg-75");
      pageTitleRef.current.classList.add("c-xl-75");
      pageTitleRef.current.classList.remove("c-lg-85");
      pageTitleRef.current.classList.remove("c-xl-90");

      userSettingRef.current.classList.add("c-lg-20");
      userSettingRef.current.classList.add("c-xl-20");
      userSettingRef.current.classList.remove("c-lg-8");
      userSettingRef.current.classList.remove("c-xl-6");
      document.querySelector('.username-label').classList.remove('display-none');
      document.querySelector('.user-control').classList.remove('display-none');
    }

  }
  return (<div className="p2">
    <div className="row justify-content-space-between">
      <div ref={userSettingRef} style={{ boxShadow: "1px 1px 10px 3px",transition:'.2s' }} className="border-rad-5 m2 p1 c-lg-20 c-xl-20">
        <div  className="row justify-content-space-arround align-items-center">
          <img src={userData.foto} style={{ width: '50px', height: '50px', borderRadius: '50px' }} />
          <span className="textcolor-black username-label" style={{ fontSize: '16pt' }}>{userData.nama}</span>
        </div>
        <div className="row mt2 justify-content-space-arround align-items-center user-control">
          <Link to="/Personal" >
            <button type="button" className="btn-primary m1 p2 border-rad-10">
              <span className="textcolor-black">User Setting</span>
            </button>
          </Link>
          <button type="button" onClick={() => { setShowModal(true) }} className="btn-danger m1 p2 border-rad-10">
            <span className="textcolor-black">Logout</span>
          </button>
        </div>

      </div>
      <div ref={pageTitleRef} style={{ boxShadow: "1px 1px 10px 3px", transition: '.2s' }} className="border-rad-5 m2 p1 c-lg-75 c-xl-75 display-flex align-items-center pl2">
        <button style={{ transition: '.2s', transform: 'rotateZ(0deg)' }} onClick={() => { toggleMinimize() }} className="btn btn-o-secondary mr4" ref={toggleMinimizeRef}>
          <FontAwesomeIcon icon={faCaretLeft} size="2x" />
        </button>
        <h1 className="textcolor-black">{page}</h1>
      </div>
    </div>
    <div className="row justify-content-space-between">
      <div ref={menuRef} style={{ boxShadow: "1px 1px 10px 3px", transition: '.2s', height: '500px', overflow: 'auto' }} className="border-rad-5 m2 p1 c-lg-20 c-xl-20">
        <MenuItem selected={page == "Home"} to="/" icon={faHome} label="Home" />
        <MenuItem selected={page == "My Ticket"} to="myTicket" icon={faClipboard} label="My Ticket" />
        {history.length > 0 && <><div className="row mt-4">
          <div className="w-100 display-flex border-solid-bottom-black-1 textcolor-black p2 m2">
            <span className="menu-label">History</span>
          </div>
        </div>
          {history.map((item, index) => (
            <HistoryItem key={index} item={item} selected={page == item.id} />
          ))}

        </>}
      </div>

      <div ref={frameRef} style={{ boxShadow: "1px 1px 10px 3px", transition: '.2s', height: '500px', overflow: 'auto' }} className="border-rad-5 m2 p1 c-lg-75 c-xl-75">
        <Routes>
          {
            CustomerRoutes.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={item.element}
              ></Route>
            ))
          }
        </Routes>
      </div>
    </div>
  </div>)
}
const MobileFrame = ({ historyList,setHistoryList, userSetting, setUserSetting, userData, setShowModal, history, page}) => {
  return (<>
    <div className="p2">
      <div className="row justify-content-space-between">
        <div style={{ boxShadow: "1px 1px 10px 3px" }} className="overflow-hidden pos-relative border-rad-5 m2 p1 justify-content-space-between w-100 display-flex align-items-center pl2 pt3 pb3">
          <button style={{ width: '50px', height: '50px' }} onClick={() => { setHistoryList(n => !n) }} className="display-flex align-items-center justify-content-center">
            <FontAwesomeIcon icon={faHistory} size="2x" />
          </button>
          <h1 className="textcolor-black overflow-hidden" style={{ textOverflow: "ellipsis", whiteSpace: 'nowrap' }}>{page}</h1>
          <button onClick={() => { setUserSetting(n => !n) }} className="display-flex align-items-center">
            <img src={userData.foto} style={{ width: '50px', height: '50px', borderRadius: '50px' }} />
            <FontAwesomeIcon icon={faCaretDown} />
          </button>

        </div>
        {userSetting &&
          <div onClick={() => { setUserSetting(false) }} style={{ boxShadow: "1px 1px 10px 3px", top: '100px', right: '20px' }} className="overflow-hidden w-50 bg-white pos-absolute border-rad-5 p1 justify-content-space-between display-flex align-items-center pl2 pt3 pb3">
            <div className="w-100 row mt2 justify-content-space-arround align-items-center">
              <div className="w-100 display-block w-75 overflow-hidden" style={{ textOverflow: "ellipsis", whiteSpace: 'nowrap' }}>
                <span className="textcolor-black" style={{ fontSize: '16pt' }}>{userData.nama}</span>
              </div>
              <Link to="/Personal" className="btn-primary m1 p2 border-rad-10 w-100 text-center">
                <span className="textcolor-black">User Setting</span>

              </Link>
              <button type="button" onClick={() => { setShowModal(true) }} className="w-100 display-block btn-danger m1 p2 border-rad-10">
                <span className="textcolor-black">Logout</span>
              </button>
            </div>
          </div>}
        {historyList &&
          <div onClick={() => { setHistoryList(false) }} style={{ boxShadow: "1px 1px 10px 3px", top: '90px', left: '20px', maxHeight: '130px' }} className="overflow-auto w-50 bg-white pos-absolute border-rad-5 p1 justify-content-space-between display-flex pl2 pt3 pb3">
            <div className="w-100 row mt2 justify-content-space-arround align-items-center">
              {history.map((item, index) => (
                <HistoryItem key={index} item={item} selected={page == item.id} />
              ))}
            </div>
          </div>}
      </div>
      <div className="row justify-content-space-between">
        <div style={{ boxShadow: "1px 1px 10px 3px", height: '450px', overflow: 'auto' }} className="border-rad-5 m2 p1 w-100">
          <Routes>
            {
              CustomerRoutes.map((item, index) => (
                <Route
                  key={index}
                  path={item.path}
                  element={item.element}
                ></Route>
              ))
            }
          </Routes>
        </div>
      </div>
    </div>
    <div className="pos-absolute w-100 bg-white display-flex justify-content-center" style={{ bottom: "0px", fontSize: '10pt' }}>
      <MenuItemMobile selected={page == "Home"} to="/" icon={faHome} label="Home" />
      <MenuItemMobile selected={page == "My Ticket"} to="myTicket" icon={faClipboard} label="My Ticket" />
    </div>
  </>)
}
export default () => {
  const userData=useSelector(state=>state.session.userData);
  const [userSetting, setUserSetting] = useState(false);
  const [showModal,setShowModal]=useState(false);
  const nav=useNavigate();
  const token=useSelector(state=>state.session.token);
  const page = useSelector(state => state.session.page);
  const [historyList, setHistoryList] = useState(false);
  const dispatch = useDispatch();
  const history = useSelector(state => state.session.history);
  let lebar=window.innerWidth;



  return (
    <>
      <ModalLogout showModal={showModal} setShowModal={setShowModal}/>
      {lebar > 992
        ?
          <DesktopFrame setHistoryList={setHistoryList} userSetting={userSetting} setUserSetting={setUserSetting} userData={userData} setShowModal={setShowModal} history={history} page={page}/>
        :
        <MobileFrame historyList={historyList} setHistoryList={setHistoryList} userSetting={userSetting} setUserSetting={setUserSetting} userData={userData} setShowModal={setShowModal} history={history} page={page} />
      }

    </>

  )
}