import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"
export default ({ count, label, icon, btn_color,FAIcon,to }) => {
  const nav=useNavigate();
  return (
    <div className="mb-sm-4 mb-xs-4 mb-md-4 mb-lg-0 c-xs-90 c-sm-90 c-md-45 c-lg-35 c-xl-30 border-solid-secondary-1 border-rad-10 p2">
      <div className="row align-items-center justify-content-center">
        <div className="w-50">
          <h1 className="textcolor-black text-center">{count}</h1>
          <div className="text-center" style={{ fontSize: '16pt' }}>{label}</div>
        </div>
        <div className="w-50">
          {
            FAIcon
            ?
            <FontAwesomeIcon icon={FAIcon} style={{ width: '73px', height: '70px' }}/>
            :
            <img src={icon} style={{ width: '73px', height: '70px' }} />}
        </div>
      </div>
      <div className="row">
        <button type="button" onClick={()=>{nav(to)}} className={btn_color + " w-100 m2 border-rad-10"} >
          <span className="textcolor-black">Detail</span>
        </button>
      </div>
    </div>
  )
}