import Home from "../Pages/Teknisi/Home";
import Tugas from "../Pages/Teknisi/Tugas";
import Personal from "../Pages/Teknisi/Personal";
import Progress from "../Pages/Teknisi/Progress";
import Complain from "../Pages/Teknisi/Complain";
const route = [
  {
    'path': '/',
    'element': <Home />
  },
  {
    'path': '/tugas',
    'element': <Tugas />
  },
  {
    'path': '/Personal',
    'element': <Personal />
  },
  {
    'path': '/Progress/:id_tugas',
    'element': <Progress />
  },
  {
    'path': '/Complain/:id_tugas',
    'element': <Complain />
  }
];
export default route;