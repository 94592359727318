import { createSlice, configureStore } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
  name: 'session',
  initialState: {
    token: "",
    type: "",
    page:"",
    history:[],
    userData:{
      nama:"",
      foto:""
    },
    verified: false
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload
    },
    setHistory: (state, action) => {
      if(state.history.length==0){
        state.history.push(action.payload);
      }
      else{
        let sama=false;
        state.history.forEach((item)=>{
          if(item.id==action.payload.id){
            sama=true;
          }
        });
        if(!sama){
          state.history.push(action.payload);
        }
      }
    },
    setVerified: (state, action) => {
      state.verified = action.payload
    },
    setPageName: (state, action) => {
      state.page = action.payload
    },
    setUserData: (state, action) => {
      state.userData = action.payload
    },
    setLogout:(state)=>{
      localStorage.removeItem('helpdesk_session_token');
      state.userData={};
      state.token="";
      state.verified=false;
      state.type = "";
    }
  },
})

// Action creators are generated for each case reducer function
export const { setToken,setHistory, setType,setPageName, setVerified, setLogout,setUserData } = counterSlice.actions

export default configureStore({
  reducer: {
    session: counterSlice.reducer
  },
})