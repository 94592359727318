import { useEffect, useState, useRef } from "react";
import { Oval } from "react-loader-spinner";
import { del, put,get } from "../../../../Libraries/Fetch";
import { useSelector } from "react-redux";
import Xicon from "../../../../assets/logo/X.png";
import { CKEditorElement,SelectInput, Textinput, ImgMultiUpload } from "../../../../Components";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import parser from "html-react-parser";
export default ({ showModal, setShowModal, Ref, saveCallback }) => {
  const token = useSelector(state => state.session.token);
  const [loading, setLoading] = useState(false);
  let [prevList, setPrevList] = useState(Ref.foto.current);
  let [data,setData]=useState({
    'tgl':"",
    "ket":""
  });
  const [prevImage,setPrevImage]=useState("");
  useEffect(() => {
    setPrevList(Ref.foto.current);
    setData({
      tgl:Ref.tgl.current,
      ket:Ref.ket.current!=null ? Ref.ket.current:""
    })
    console.log(Ref)
  }, [showModal]);
  const Form=()=>{
    return (
      <form className='modal-body'>
        <div className="w-100 mt2">
          <label>Tanggal</label>
        </div>
        <div className="w-100 mt2 textcolor-black">
         {data.tgl}
        </div>
        <div className="w-100 mt2">
          <label>Deskripsi</label>
        </div>
        <div style={{ boxSizing: 'border-box' }} className="textcolor-black bg-light p2 border-solid-dark-secondary-1 w-100 mt2 bg-lignt-secondary border-rad-5">
          {parser(data.ket)}
        </div>
        <div className="w-100 mt2">
          <ImgMultiUpload
            prevOnly={true}
            onImageClick={(item) => {
              setPrevImage(item.target.src);
            }}
            name="gambar_e"
            prevList={prevList}
            keyToDisplay="_foto"
            closeCallback={async (index) => {
              Ref.foto.current = Ref.foto.current.filter((item) => Ref.foto.current.indexOf(item) != index)
              setPrevList((n) => {
                let nilaiLama = n;
                return n.filter((item) => n.indexOf(item) != index);
              })
            }}
            addCallback={(file) => {
              return "";
            }}
            label="Gambar (Klik untuk memperbesar)" />
        </div>
      </form>
    )
  }
  const PrevImage=()=>{
    return (
    <div className="modal-body">

      <div className="display-flex justify-content-md-flex-start justify-content-xl-center justify-content-lg-center w-100">
        <div className="pos-relative mt4">
          <button
            type="button"
            onClick={() => {
              setPrevImage("");
            }}
            className='border-solid-dark-danger-3 bg-danger pos-absolute border-rad-5 display-block' style={{ width: '25px', height: '25px', right: '5px', top: '5px' }}>
            <img src={Xicon} style={{ width: '100%', height: '100%' }} />
          </button>
          <img
            className="m4"
            src={prevImage}
            style={{ width: '400px',height:'250px'}}
          />
        </div>
      </div>
    </div>

    )
  }
  return (
    <div className={showModal ? "modal active" : "modal"}>
      <div className='modal-container'>
        <div className='modal-content'>
          <button onClick={() => { setShowModal(false) }} className='pos-absolute btn-danger' style={{ width: '25px', height: '32px', textAlign: 'center', top: '-10px', right: '-10px' }}>
            X
          </button>
          <div className="modal-header textcolor-black">
            <h1>DETAIL PROGRESS</h1>
          </div>
          {
            prevImage!=""
            ?
            <PrevImage/>
            :
            <Form/>
          }
        </div>
      </div>
    </div>
  )
}