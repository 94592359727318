import { useEffect, useState, useRef } from "react";
import { get } from "../../../../../Libraries/Fetch";
import { useDispatch, useSelector } from "react-redux";
import { Oval } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { SelectInput,Textinput } from "../../../../../Components";
import ModalAdd from "./Components/ModalAdd";
import ModalDetail from "./Components/ModalDetail";
import ModalConfirm from "./Components/ModalConfirm";
import Options from "./Components/Options";
export default function App({id_tugas,status}) {
  const token = useSelector(state => state.session.token);
  const dispatch = useDispatch();
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageComponent, setPageComponent] = useState([])
  const [data, setData] = useState({
    data: [],
    maxPage: 1
  });
  const ModalData = {
    tableData: {
      limit: useRef(10),
      search: useRef(null)
    },
    detail: {
      tgl: useRef(""),
      ket: useRef(""),
      topik: useRef(""),
      foto: useRef([])
    },
    add: {
      id_tugas: useRef(null),
      ket: useRef(null),
      topik: useRef(0),
      foto: useRef([]),
    },
    confirm: {
      id_proses: useRef(null)
    }
  }
  const page = useRef(1);
  const readData = async () => {
    setLoading(true);
    let q = ModalData.tableData.search.current.value;
    let req = "";
    if (q != "") {
      req = await get("Teknisi/Progress/search/"+id_tugas+"/" + page.current + "/" + ModalData.tableData.limit.current + "?q=" + q, token);
    }
    else {
      req = await get("Teknisi/Progress/readProgress/" + id_tugas + "/" + page.current + "/" + ModalData.tableData.limit.current, token);
    }

    setData(req.data.data);
    setLoading(false);
    setPageComponent((n) => {
      let objek = {
        minPage: 1,
        maxPage: 1,
      };
      let pageComponentBaru = [];
      objek.minPage = page.current - 2;
      if (objek.minPage <= 0) {
        objek.minPage = 1;
      }
      if (page.current == 1) {
        objek.maxPage = page.current + 4;
      }
      else if (page.current == 2) {
        objek.maxPage = page.current + 3;
      }
      else if (page.current > 2) {
        objek.maxPage = page.current + 2;
      }
      if (objek.maxPage > req.data.data.maxPage) {
        objek.maxPage = req.data.data.maxPage;
      }
      for (let i = objek.minPage; i <= objek.maxPage; i++) {
        if (i == page.current) {
          pageComponentBaru.push(
            <button key={i} className="btn-success border-solid-success-1 border-rad-5 m1">
              {i}
            </button>
          )
        }
        else {
          pageComponentBaru.push(
            <button key={i} onClick={async () => { page.current = i; await readData() }} className="btn-o-success border-solid-success-1 border-rad-5 m1">
              {i}
            </button>
          )
        }
      }
      console.log(objek);
      return pageComponentBaru;
    })
    setTimeout(() => {
      console.log(pageComponent);
    }, 1000);
  }
  const readDetail = async(id_proses) => {
    const req = await get("Teknisi/Progress/readOne/"+id_proses, token);
    if (req.data.data != "") {
      ModalData.detail.ket.current=req.data.data.ket;
      ModalData.detail.topik.current = req.data.data.topik;
      ModalData.detail.tgl.current = req.data.data._tgl_proses;
      ModalData.detail.foto.current = req.data.data._foto;
    }
    setShowModalDetail(true);
  }
  const handleConfirm=(id_proses)=>{
    ModalData.confirm.id_proses.current=id_proses;
    setShowModalConfirm(true);
  }
  useEffect(() => {
    readData();
    ModalData.add.id_tugas.current=id_tugas;
  }, [])
  return (
    <div style={{height:'450px'}} className="overflow-auto c-sm-100 c-md-100 c-lg-55 c-xl-65 border-solid-primary-1 p3 border-rad-5">
      <div className="m4">
        {(status != "C" && status != "F")
        &&
        <ModalAdd
          showModal={showModalAdd}
          setShowModal={setShowModalAdd}
          Ref={ModalData.add}
          saveCallback={async() => {
            await readData();
          }}
        />
        }
        <ModalDetail
          showModal={showModalDetail}
          setShowModal={setShowModalDetail}
          Ref={ModalData.detail}
        />

        <ModalConfirm
          showModal={showModalConfirm}
          setShowModal={setShowModalConfirm}
          Ref={ModalData.confirm}
          saveCallback={() => {
            readData();
          }}
        />
        <>
          {(status != "C" && status!="F")&&<div className="w-100 display-flex">
            <button type="button" onClick={() => { setShowModalAdd(true) }} className="btn-primary border-rad-5">
              <FontAwesomeIcon icon={faPlus} /> Add Progress
            </button>
          </div>}
          <div className="row w-100 mt2 justify-content-space-between align-items-center">
            <div className="c-xs-100 c-sm-100 c-md-100 c-lg-40">
              <div className="display-flex align-items-center">
                <span>Show</span>
                <SelectInput
                  onChange={async (e) => {
                    page.current = 1;
                    ModalData.tableData.limit.current = e.target.value;
                    await readData();
                  }}
                  className="display-block ml2 mr2"
                  style={{ width: "45px" }}>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                </SelectInput>
                <span>Item</span>
              </div>
            </div>
            <div className="c-xs-100 c-sm-100 c-md-100 c-lg-40">
              <Textinput placeholder="search"
                Ref={ModalData.tableData.search}
                onKeyDown={async (e) => {
                  let q = ModalData.tableData.search.value
                  if (e.key == "Enter") {
                    page.current = 1;
                    await readData();
                  }
                }}
                icon={faSearch} />
            </div>
          </div>
          {
            loading
              ?
              <div className="row justify-content-center mt2">
                <Oval color="#007bff" height={50} width={50} />
              </div>
              :
              <div className="row mt2">
                <div className="w-100 mt2 table-container">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Topik</th>
                        <th>Tanggal</th>
                        <th>Control</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        data.data.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.topik}</td>
                              <td>
                                {item._tgl_proses}
                              </td>
                              <td>
                                <Options
                                  item={item}
                                  readDetail={readDetail}
                                  handleConfirm={handleConfirm}
                                />
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
          }
          <div className="display-flex justify-content-flex-end w-100">
            <button onClick={() => { page.current = 1; readData(); }} className="btn-o-success border-solid-success-1 border-rad-5 m1">
              {"|<"}
            </button>
            <button onClick={() => { page.current -= 1; if (page.current <= 0) { page.current = 1 } readData(); }} className="btn-o-success border-solid-success-1 border-rad-5 m1">
              {"<<"}
            </button>
            {pageComponent}
            <button onClick={() => { page.current += 1; if (page.current > data.maxPage) { page.current = data.maxPage } readData(); }} className="btn-o-success border-solid-success-1 border-rad-5 m1">
              {">>"}
            </button>
            <button onClick={() => { page.current = data.maxPage; readData(); }} className="btn-o-success border-solid-success-1 border-rad-5 m1">
              {">|"}
            </button>
          </div>
        </>
      </div>
    </div>
  )
}