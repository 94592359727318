import React, { useEffect, useState, useRef } from "react";
import { Oval } from "react-loader-spinner";
import { post,get } from "../../../../Libraries/Fetch";
import { useSelector } from "react-redux";
import { CKEditorElement,SelectInput,ReactSelect,ImgMultiUpload } from "../../../../Components";
import { faClipboard,faComputer } from "@fortawesome/free-solid-svg-icons";
export default ({ showModal, setShowModal, Ref,saveCallback }) => {
  const token = useSelector(state => state.session.token);
  const [loading, setLoading] = useState(false);
  const initialErrorMessage = {
    topik_problem: '',
    deskripsi_problem: '',
    prioritas:''
  }
  let [errorMessage, setErrorMessage] = useState(initialErrorMessage);
  let [prevList, setPrevList] = useState([]);
  let [prioritas,setPrioritas]=useState([]);
  const readTopikMasalah=async (query)=>{
    if(!query){
      query=""
    }
      const req=await get("select/topikMasalah?q="+query);
      return req;
  }
  const readInfraStruktur=async (query)=>{
    if(!query){
      query=""
    }
    const req=await get("select/infrastruktur/"+Ref.topik_problem.current?.value+"?q="+query);
    return req;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(loading){return "";}
    setLoading(true);

    let form = new FormData();
    form.append("topik_problem", Ref.topik_problem.current.value);
    form.append("infrastruktur", Ref.infrastruktur.current.value);
    form.append("prioritas", Ref.prioritas.current.value);
    form.append("deskripsi_problem", Ref.deskripsi_problem.current.data.get());
    Ref.foto.current.forEach((item, index) => {
      form.append("foto[]", item);
    })
    let req = await post('Customer/Tiket', form, token);
    if (req.data.status == "success") {
      setLoading(false);
      alert(req.data.message)
      setShowModal(false);
      setPrevList([]);
      Ref.foto.current=[];
      saveCallback();

    }
    else if (req.data.status == "error") {
      setLoading(false);
      alert(req.data.message);
      if (req.data.data.deskripsi_problem) {
        setErrorMessage((n) => { return { ...n, deskripsi_problem: req.data.data.deskripsi_problem[0] } });
      }
      if (req.data.data.topik_problem) {
        setErrorMessage((n) => { return { ...n, topik_problem: req.data.data.topik_problem[0] } });
      }
    }
  }
  const readPrioritas=async()=>{
    const req=await get("select/prioritas");
    setPrioritas(req.data);
  }
  useEffect(()=>{
    readPrioritas();
  },[])
  return (
    <div className={showModal ? "modal active" : "modal"}>
      <div className='modal-container'>
        <div className='modal-content'>
          <button onClick={() => { setShowModal(false) }} className='pos-absolute btn-danger' style={{ width: '25px', height: '32px', textAlign: 'center', top: '-10px', right: '-10px' }}>
            X
          </button>
          <div className="modal-header">
            <h1>ADD TICKET</h1>
          </div>
          <form onSubmit={handleSubmit} className='modal-body'>
            <ReactSelect Ref={Ref.topik_problem} label="Topik Masalah" placeholder="Topik Masalah"
              icon={faClipboard} errorMessage={errorMessage.topik_problem} loadOptions={readTopikMasalah} optionsMap={(item)=>{return {value:item.id_topik,label:item.nama}}}>
            </ReactSelect>
            <ReactSelect Ref={Ref.infrastruktur} label="Infrastruktur"
            loadOptions={readInfraStruktur} optionsMap={(item)=>{return {value:item.id_infrastruktur,label:item.nama}}}
            placeholder={"infrastruktur"}
              icon={faComputer} errorMessage={errorMessage.infrastruktur} >
            </ReactSelect>
            <SelectInput Ref={Ref.prioritas} label="Prioritas" icon={faClipboard} errorMessage={errorMessage.prioritas}>
              {
                prioritas.map((item,index)=>(
                  <option key={index} value={item.id_prioritas}>{item.prioritas}</option>
                ))
              }
            </SelectInput>
            <CKEditorElement
              label="Deskripsi"
              callBack={(editor) => { Ref.deskripsi_problem.current = editor }} />
            <div className="w-100 mt2">
              <ImgMultiUpload
                name="gambar"
                prevList={prevList}
                closeCallback={(index) => {
                  Ref.foto.current = Ref.foto.current.filter((item) => Ref.foto.current.indexOf(item) != index)
                  setPrevList((n) => {
                    let nilaiLama = n;
                    return n.filter((item) => n.indexOf(item) != index);
                  })
                }}
                addCallback={(file) => {
                  setPrevList((n) => {
                    return [...n, URL.createObjectURL(file)];
                  });
                  Ref.foto.current.push(file);

                }}
                label="Gambar" />
            </div>
            <div className="w-100 dislay-flex mt2">
              <button disabled={false} className="btn-o-primary align-items-center border-rad-5 display-flex textcolor-black p2 m1">
                {loading && <Oval height={25} width={25} />}
                Simpan
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}