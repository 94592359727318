import { useState } from "react";
import { Oval } from "react-loader-spinner";
import { del } from "../../../../Libraries/Fetch";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots,faCheck,faXmark,faPencilSquare,faTrash,faHistory,faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
export default ({
  item,
  readComplain
}) => {
  let token = useSelector(state => state.session.token);
  const [loadingFeedback, setLoadingFeedback] = useState(false);
  return (
    <>
      <button
        style={{ width: '25px' }}
        onClick={async () => {
          setLoadingFeedback(true);
          await readComplain(item.id_complain);
          setLoadingFeedback(false);
        }}
        type="button" className="m1 btn-o-secondary textcolor-black border-rad-5">
        {loadingFeedback ?
          <Oval color="black" height={15} width={15} />
          :
          <FontAwesomeIcon icon={faCircleQuestion} />
        }
      </button>
    </>
  )
}