import { useEffect, useState } from "react";
import { Public,Customer,Admin,Teknisi} from './Layout';
import { useSelector,useDispatch } from "react-redux";
import { get } from "./Libraries/Fetch";
import { setToken,setType,setVerified,setUserData } from "./store";
import NotVerified from "./Pages/Public/NotVerified";
import { Oval } from "react-loader-spinner";
export default function App() {
  const dispatch = useDispatch();
  const [loading,setLoading]=useState(true);
  const token = useSelector((state) => state.session.token);
  const type = useSelector((state) => state.session.type);
  const verified = useSelector((state) => state.session.verified);
  const verify=async()=>{
    setLoading(true);
    let retrieveToken = localStorage.getItem('helpdesk_session_token');
    console.log(retrieveToken)
    const req=await get('verify',retrieveToken);
    if(req.data.status=="error"){
      localStorage.removeItem('helpdesk_session_token');
    }
    else{
      dispatch(setToken(retrieveToken));
      dispatch(setType(req.data.data.tipe));
      dispatch(setUserData(req.data.data));
      dispatch(setVerified(req.data.data.verifikasi==1 ? true : false));
    }
    setLoading(false);
  }
  useEffect(() => {
    verify();
  }, []);
  return (
    <>
    {
      loading
      ?
      <div style={{height:'100vh'}} className="display-flex align-items-center justify-content-center">
        <Oval color="#007bff" height={50} width={50} />
      </div>
      :
          <>
            {type == ""
              ?
              <Public />
              :
              <>
                {type == "A" && <Admin />}
                {type == "C"
                  &&
                  <>
                    {verified
                      ?
                      <Customer/>
                      :
                      <NotVerified/>
                    }
                  </>}
                {type == "T" && <Teknisi/>}
              </>
            }
          </>
    }
    </>

  )
}