import { useEffect, useState, useRef } from "react";
import { Oval } from "react-loader-spinner";
import { post,get } from "../../../../Libraries/Fetch";
import { useSelector } from "react-redux";
import { CKEditorElement,SelectInput, Textinput, ImgUpload, PasswordInput } from "../../../../Components";
import { faClipboard, faEnvelope, faMapMarker, faPhone, faUserCircle,faUser, faVenusMars, faLock } from "@fortawesome/free-solid-svg-icons";
export default ({ showModal, setShowModal, Ref,saveCallback }) => {
  const token = useSelector(state => state.session.token);
  const [loading, setLoading] = useState(false);
  const initialErrorMessage = {
    nama: ''
  }
  let [errorMessage, setErrorMessage] = useState(initialErrorMessage);
  
  return (
    <div className={showModal ? "modal active" : "modal"}>
      <div className='modal-container'>
        <div className='modal-content'>
          <button onClick={() => { setShowModal(false) }} className='pos-absolute btn-danger' style={{ width: '25px', height: '32px', textAlign: 'center', top: '-10px', right: '-10px' }}>
            X
          </button>
          <div className="modal-header">
            <h1>DETAIL</h1>
          </div>
          <form className='modal-body'>
            <Textinput Ref={Ref.nama} label="nama" placeholder="nama"
              icon={faClipboard} errorMessage={errorMessage.nama} />
            
          </form>
        </div>
      </div>
    </div>
  )
}