import { useState } from "react";
import { Oval } from "react-loader-spinner";
import { del } from "../../../../../../Libraries/Fetch";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots,faCheck,faXmark,faPencilSquare,faTrash,faHistory,faCircleQuestion, faQuestion } from "@fortawesome/free-solid-svg-icons";
export default ({
  item,
  readDetail,
  handleConfirm
}) => {
  let token = useSelector(state => state.session.token);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [loadingFeedback, setLoadingFeedback] = useState(false);
  const [loadingDel, setLoadingDel] = useState(false);
  return (
    <div className="display-flex justify-content-center">
      {item.status=="I"&&<button
        style={{ width: '25px' }}
        onClick={async () => {
          handleConfirm(item.id_proses)
        }} type="button" className="m1 display-flex justify-content-center btn-o-secondary textcolor-black border-rad-5 display-flex align-items-center">
        {loadingDetail ? <Oval color="black" height={15} width={15} />
          :
          <FontAwesomeIcon icon={faCheck} />
        }

      </button>}
      <button
        style={{ width: '25px' }}
        onClick={async () => {
          setLoadingDetail(true);
          await readDetail(item.id_proses);
          setLoadingDetail(false)
        }} type="button" className="m1 display-flex justify-content-center btn-o-secondary textcolor-black border-rad-5 display-flex align-items-center">
        {loadingDetail ? <Oval color="black" height={15} width={15} />
          :
          <FontAwesomeIcon icon={faQuestion} />
        }

      </button>
    </div>
  )
}