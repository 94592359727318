import { useState } from "react";
import Camera from "../assets/logo/camera.png";
import Xicon from "../assets/logo/X.png";
import { Oval } from "react-loader-spinner";
export default function ({
  keyToDisplay,
  prevList,
  label,
  Ref,
  name,
  closeCallback,
  prevOnly,
  onImageClick,
  addCallback }) {
  prevOnly = prevOnly == null ? false : prevOnly;
  const handleChange = (e) => {
    if (e.target.files[0]) {
      addCallback(e.target.files[0]);
    }
  }
  const PrevComponent = ({ prevItem, closeCallback, index }) => {
    const [loading, setLoading] = useState(false);
    return (
      <div className='mr4 bg-light-secondary display-flex align-items-center justify-content-center pos-relative' style={{ width: '200px', height: '200px' }}>

        {!prevOnly && <button
          type="button"
          onClick={async () => {
            setLoading(true);
            await closeCallback(index);
            setLoading(false);
          }}
          className='border-solid-dark-danger-3 bg-danger pos-absolute border-rad-5 display-block' style={{ width: '25px', height: '25px', right: '-10px', top: '-10px' }}>
          <img src={Xicon} style={{ width: '100%', height: '100%' }} />
        </button>}
        <>{
          loading
            ?
            <><Oval color="#00BFFF" height={50} width={50} />
              <h4>Deleting</h4>
            </>
            :
            <img
              onClick={(e)=>{
                onImageClick(e);
              }}
              src={prevItem}
              style={{ width: '100%', height: '100%' }} />
        }
        </>
      </div>
    )
  }
  return (
    <div>
      {!prevOnly
      ?
        <label>{label}</label>
      :
      <>
        {
          prevList.length > 0
            ?
            <label>{label}</label>
            :
            <></>
        }
      </>
      }

      <input type="file" ref={Ref} onChange={handleChange} name={name} style={{ display: 'none' }} id={name} />
      <div className="w-100 overflow-auto row pt4">
        {
          prevList.map((item, index) => {
            if (keyToDisplay != undefined) {
              if (item[keyToDisplay] != undefined) {
                return (
                  <PrevComponent
                    closeCallback={closeCallback}
                    key={index}
                    index={index}
                    prevItem={item[keyToDisplay]}
                  />
                )
              }
              else {
                return (
                  <PrevComponent
                    closeCallback={closeCallback}
                    key={index}
                    index={index}
                    prevItem={item}
                  />
                )
              }
            }
            else {
              return (
                <PrevComponent
                  closeCallback={closeCallback}
                  key={index}
                  index={index}
                  prevItem={item}
                />
              )
            }
          })
        }
        {!prevOnly&&<label htmlFor={name} className='bg-light-secondary display-flex align-items-center justify-content-center pos-relative' style={{ width: '200px', height: '200px' }}>
          <img src={Camera} style={{ width: '50%', height: '50%' }} />
        </label>}
      </div>

    </div>
  )
}