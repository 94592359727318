import { useEffect,useState,useRef } from "react";
import {get} from "../../../Libraries/Fetch";
import { useDispatch,useSelector } from "react-redux";
import { Oval } from "react-loader-spinner";

import JobDetail from "./Components/JobDetail";
import { useParams } from "react-router-dom";
import { setPageName } from "../../../store";
import Table from "./Components/Table";
export default function App() {
  const token=useSelector(state=>state.session.token);
  const dispatch=useDispatch();
  const [showModalComplain,setShowModalComplain]=useState(false);
  const [loading,setLoading]=useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const {id_tiket}=useParams();
  const [data,setData]=useState({
    data:[]
  });
  const readData = async () => {
    setLoading(true);
    let reqStatus = await get("Admin/Complain/" + id_tiket, token);
    setData(reqStatus.data);
    setLoading(false)
  }
  useEffect(()=>{
    dispatch(setPageName('Data Complain'));
    readData();
  },[])
  return (
    <div className="m4">
      <>
        {
          loading
            ?
            <div className="row justify-content-center mt2">
              <Oval color="#007bff" height={50} width={50} />
            </div>
            :
            <div className="row mt2 justify-content-space-between">
              <JobDetail data={data.data[0]}/>

              <div className="c-xs-100 c-sm-100 c-md-100 c-lg-55 c-xl-65 border-solid-primary-1 p3 border-rad-5">
                <Table id_tiket={id_tiket} />
              </div>
            </div>
        }
      </>
    </div>
  )
}