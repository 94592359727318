import axios from 'axios';
import { login,logout } from "../store";
import { useSelector,useDispatch } from 'react-redux';
const API_URL = process.env.REACT_APP_API_URL;
const getToken = () => {
  let token = localStorage.getItem('helpdesk_session_token');
  if(token){
    return token;
  }else{
    return "";
  }
};
const post = async (endpoint, form,token="") => {
  try {
    const response = await axios.post(API_URL + endpoint, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' +token
      }
    });
    // Handle the response
    return { code: response.status, data: response.data };
  } catch (error) {
    // Handle error
    // console.error('Error:', error.response.data);
    return {code:error.response.status,data:error.response.data};
  }
}
const download = async (endpoint, form, token = "") => {
  try {
    const response = await axios.post(API_URL + endpoint, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + token,
      },
      responseType: 'blob'
    });
    // Handle the response
    return response;
  } catch (error) {
    // Handle error
    console.error('Error:', error.response.data);
    return error;
  }
}
const get = async (endpoint, token="") => {
  console.log(process.env.REACT_APP_API_URL)
  try {
    const response = await axios.get(API_URL + endpoint, {
      headers: {
        'Authorization': 'Bearer ' +token
      }
    });
    return { code: response.status, data: response.data };
  } catch (error) {
    // Handle error
    console.log(error)
    // console.error('Error:', error.response.data);
    return { code: error.response.status, data: error.response.data };
  }
}
const put = async (endpoint, form,token="") => {
  if (form == null) {
    form = new FormData();
  }
  form.append("_method","PUT");

  try {
    const response = await axios.post(API_URL + endpoint, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' +token
      }
    });
    // Handle the response
    return { code: response.status, data: response.data };
  } catch (error) {
    // Handle error
    // console.error('Error:', error.response.data);
    return { code: error.response.status, data: error.response.data };
  }
}
const del = async (endpoint, form,token="") => {
  if(form==null){
    form=new FormData();
  }
  form.append("_method", "delete");

  try {
    const response = await axios.post(API_URL + endpoint, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' +token
      }
    });
    // Handle the response
    return { code: response.status, data: response.data };
  } catch (error) {
    // Handle error
    // console.error('Error:', error.response.data);
    return { code: error.response.status, data: error.response.data };
  }
}
export { post,get,put,del,download };