import { useEffect, useState, useRef } from "react";
import { Oval } from "react-loader-spinner";
import { del, put, get } from "../../../../Libraries/Fetch";
import { useSelector } from "react-redux";
import Xicon from "../../../../assets/logo/X.png";
import { CKEditorElement, SelectInput, Textinput, ImgMultiUpload } from "../../../../Components";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import parse from 'html-react-parser';
export default ({ showModal, setShowModal, Ref, saveCallback }) => {
  const token = useSelector(state => state.session.token);
  const [loading, setLoading] = useState(false);
  let [prevList, setPrevList] = useState([]);
  let [data, setData] = useState({
    'topik_problem': "",
    "deskripsi_problem": "",
    "nama_customer": "",
    "prioritas": "",
    "tugas_gagal": null
  });
  const [prevImage, setPrevImage] = useState("");
  const readDetail = async (id_tugas) => {
    setLoading(true);
    let req = await get("Teknisi/Tugas/readOne/" + id_tugas, token);
    setData({
      topik_problem: req.data.data.infrastruktur.topik_masalah.nama,
      prioritas: req.data.data.prioritas.prioritas,
      nama_customer: req.data.data.nama_customer,
      deskripsi_problem: req.data.data.isi_problem,
      tugas_gagal: req.data.data._tugas_gagal
    });
    setPrevList(req.data.data._foto);
    setLoading(false);
  }
  useEffect(() => {
    if (showModal) {
      readDetail(Ref.id_tugas.current);
    }
  }, [showModal]);
  const Form = () => {
    return (
      <form className='modal-body'>
        <div className="w-100 mt2">
          <label>Topik Masalah</label>
        </div>
        <div className="w-100 mt2 textcolor-black">
          {data.topik_problem}
        </div>
        <div className="w-100 mt2">
          <label>Nama Customer</label>
        </div>
        <div className="w-100 mt2 textcolor-black">
          {data.nama_customer}
        </div>
        <div className="w-100 mt2">
          <label>Prioritas</label>
        </div>
        <div className="w-100 mt2 textcolor-black">
          {data.prioritas}
        </div>
        <div className="w-100 mt2">
          <label>Deskripsi Problem</label>
        </div>
        <div style={{ boxSizing: 'border-box' }} className="textcolor-black bg-light p2 border-solid-dark-secondary-1 w-100 mt2 bg-lignt-secondary border-rad-5">
          {parse(data.deskripsi_problem)}
        </div>
        <div className="w-100 mt2">
          <ImgMultiUpload
            prevOnly={true}
            onImageClick={(item) => {
              setPrevImage(item.target.src);
            }}
            name="gambar_e"
            prevList={prevList}
            keyToDisplay="_foto"
            label="Gambar (Klik untuk memperbesar)" />
        </div>
        {
          data.tugas_gagal != null
          &&
          <>
            <div className="w-100 mt2">
              <label>Deskripsi Tugas Gagal</label>
            </div>
            <div style={{ boxSizing: 'border-box' }} className="textcolor-black bg-light p2 border-solid-dark-secondary-1 w-100 mt2 bg-lignt-secondary border-rad-5">
              {parse(data.tugas_gagal.keterangan)}
            </div>
            <ImgMultiUpload
              prevOnly={true}
              onImageClick={(item) => {
                setPrevImage(item.target.src);
              }}
              name="gambar_f"
              prevList={data.tugas_gagal._foto}
              keyToDisplay="_foto"
              label="Gambar (Klik untuk memperbesar)" />
          </>
        }
      </form>
    )
  }
  const PrevImage = () => {
    return (
      <div className="modal-body">

        <div className="display-flex justify-content-md-flex-start justify-content-xl-center justify-content-lg-center w-100">
          <div className="pos-relative mt4">
            <button
              type="button"
              onClick={() => {
                setPrevImage("");
              }}
              className='border-solid-dark-danger-3 bg-danger pos-absolute border-rad-5 display-block' style={{ width: '25px', height: '25px', right: '5px', top: '5px' }}>
              <img src={Xicon} style={{ width: '100%', height: '100%' }} />
            </button>
            <img
              className="m4"
              src={prevImage}
              style={{ width: '400px', height: '250px' }}
            />
          </div>
        </div>
      </div>

    )
  }
  return (
    <div className={showModal ? "modal active" : "modal"}>
      <div className='modal-container'>
        <div className='modal-content'>
          <button onClick={() => { setShowModal(false) }} className='pos-absolute btn-danger' style={{ width: '25px', height: '32px', textAlign: 'center', top: '-10px', right: '-10px' }}>
            X
          </button>
          <div className="modal-header textcolor-black">
            <h1>DETAIL TIKET</h1>
          </div>
          {
            loading
              ?
              <div style={{ height: '400px' }} className="w-100 display-flex align-items-center justify-content-center">
                <Oval color="black" height={50} width={50} />
              </div>
              :
              <>
                {
                  prevImage != ""
                    ?
                    <PrevImage />
                    :
                    <Form />
                }
              </>
          }
        </div>
      </div>
    </div>
  )
}