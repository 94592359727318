import { faEnvelopeOpen,faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { faPhone,faGlobe,faVenusMars } from "@fortawesome/free-solid-svg-icons";
import { Textinput,SelectInput,PasswordInput } from "../../../../Components";
import { Link } from "react-router-dom";
export default function (props){
  return (
    <div className='c-xs-100 c-sm-100 c-md-100 c-lg-45 c-xl-45 m2'>

      <div className='display-flex justify-content-center'>
        <div className='mt2 mb2 textcolor-black' style={{ fontWeight: 'bold', fontSize: '24pt' }}>Sign Up</div>
      </div>
      <div className='display-flex justify-content-center'>
        <span>Already have an account ? <Link to="/"><span className='textcolor-primary'>Sign In</span></Link> </span>
      </div>

      <Textinput Ref={props.Ref.username} label="Username" placeholder="username" icon={faUserCircle} errorMessage={props.errorMessage.username} />
      <Textinput Ref={props.Ref.fullname} label="Nama Lengkap" placeholder="nama lengkap" icon={faUserCircle} errorMessage={props.errorMessage.fullname} />
      <Textinput Ref={props.Ref.email} label="Email" placeholder="email" icon={faEnvelopeOpen} errorMessage={props.errorMessage.email} />
      <SelectInput Ref={props.Ref.jekel} label="Jenis Kelamin" icon={faVenusMars} errorMessage={props.errorMessage.jekel}>
        <option value="L">Laki-laki</option>
        <option value="P">Perempuan</option>
      </SelectInput>
      <Textinput Ref={props.Ref.alamat} label="Alamat" placeholder="Alamat" icon={faGlobe} errorMessage={props.errorMessage.alamat} />
      <Textinput Ref={props.Ref.telp} label="Telp" placeholder="Telp (ex: 0812345xxxxx)" icon={faPhone} errorMessage={props.errorMessage.telp} />
      <PasswordInput
        Ref={props.Ref.password}
        label="Password"
        errorMessage={props.errorMessage.password}
        placeholder="password" />
      <PasswordInput
        Ref={props.Ref.confirmation}
        label="Confirm Password"
        errorMessage={props.errorMessage.confirmation}
        placeholder="password" />
    </div>
  )
}