import { useEffect, useState,useRef } from "react";
import {get} from "../../../Libraries/Fetch";
import { useSelector } from "react-redux";
import { Oval } from "react-loader-spinner";
import ModalEditData from "./Components/ModalEditData";
import ModalEditPassword from "./Components/ModalEditPassword";
import {setUserData,setPageName} from "../../../store";
import { useDispatch } from "react-redux";
export default function App() {
  const dispatch = useDispatch();
  const token=useSelector(state=>state.session.token);
  const [data,setData]=useState({
    'username':"",
    'jekel':"",
    'email':"",
    "_foto":"",
    "_jekel":""
  });
  const ModalData = {
    editData: {
      username: useRef(null),
      jekel: useRef(null),
      email: useRef(null),
      foto: useRef(null),
      prevFoto: useRef(null)
    },
    editPassword: {
      lama: useRef(null),
      baru: useRef(null),
      konfirmasi: useRef(null)
    }
  }
  const [loading,setLoading]=useState(true);
  const [showEditData,setShowEditData] = useState(false);
  const [showEditPassword, setShowEditPassword] = useState(false);
  const readData=async()=>{
    setLoading(true);
    let req=await get("Admin/Personal",token)
    setData(req.data.data[0]);
    setLoading(false);
    ModalData.editData.username.current.value = req.data.data[0].username;
    ModalData.editData.email.current.value = req.data.data[0].email;
    ModalData.editData.jekel.current.value = req.data.data[0].jekel;
    ModalData.editData.prevFoto.current = req.data.data[0]._foto;
    return req.data.data[0];
  }

  useEffect(()=>{
    dispatch(setPageName('User Setting'))
    readData();
  },[])
  return (
    <>
      <ModalEditData
        Ref={ModalData.editData}
        showModal={showEditData}
        setShowModal={setShowEditData}
        saveCallback={async () => {
          const req = await readData();
          dispatch(setUserData({
            nama: req.username,
            foto: req._foto,
          }));
        }} />
      <ModalEditPassword
        Ref={ModalData.editPassword}
        showModal={showEditPassword}
        setShowModal={setShowEditPassword}
        saveCallback={async () => {
          await readData();
        }} />
      {
        loading
        ?
        <div className="w-100 display-flex justify-content-center align-items-center">
            <Oval color="#007bff" height={50} width={50} />
        </div>
        :
        <div className="row m4">
          <div className="w-100">Username</div>
          <div className="textcolor-black w-100 mb2" style={{fontSize:'16pt'}}>{data.username}</div>
          <div className="w-100">Email</div>
          <div className="textcolor-black w-100 mb2" style={{ fontSize: '16pt' }}>{data.email}</div>
          <div className="w-100">Jenis Kelamin</div>
          <div className="textcolor-black w-100 mb2" style={{ fontSize: '16pt' }}>{data._jekel}</div>
          <div className="w-100">Photo</div>
          <div className="w-100 mt2">
            <img src={data._foto} style={{width:'160px',height:'200px'}} className="border-rad-10"/>
          </div>
          <div className="w-100 mt2 display-flex">
            <button onClick={()=>{setShowEditData(true)}} type="button" className="btn-o-primary m1 p2 border-rad-10">
              <span className="textcolor-black">Edit Data</span>
            </button>
              <button onClick={() => { setShowEditPassword(true) }} type="button" className="btn-o-primary m1 p2 border-rad-10">
              <span className="textcolor-black">Edit Password</span>
            </button>
          </div>
        </div>

      }
    </>

  )
}