import { useState, useEffect, useRef, forwardRef, useContext } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link,useParams,useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Textinput } from '../../../Components';
import { PasswordInput } from '../../../Components';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import BigLogo from "../../../assets/img/helpdesk logo2.png";
import Google from "../../../assets/logo/Google.png";
import { post } from '../../../Libraries/Fetch';
import { Oval } from 'react-loader-spinner';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
export default function App() {
  let captcha=useRef(null);
  let nav=useNavigate();
  let {kd_reset}=useParams();
  const initialErrorMessage = {
    password: '',
    confirm: '',
    captcha: '',
  }
  let [errorMessage, setErrorMessage] = useState(initialErrorMessage);
  let confirm = useRef(null);
  let password = useRef(null);
  let captchaElement=useRef(null);
  const [loading,setLoading]=useState(false);
  const doReset= async(e) => {
    setErrorMessage(initialErrorMessage)
    setLoading(true);
    e.preventDefault();
    let fr = new FormData();
    fr.append('password', password.current.value);
    fr.append('confirm', confirm.current.value);
    fr.append('captcha', captcha.current);
    const req = await post('resetPassword/'+kd_reset, fr);
    if (req.code==200){
      alert('Reset berhasil, silahkan login ulang');
      nav('/');
    }
    else{
      if (req.data.status == "error") {
        if (req.data.message.username) {
          setErrorMessage((n) => { return { ...n, username: req.data.message.username[0] } });
        }
        if (req.data.message.password) {
          setErrorMessage((n) => { return { ...n, password: req.data.message.password[0] } });
        }
        if (req.data.message.captcha) {
          setErrorMessage((n) => { return { ...n, captcha: req.data.message.captcha[0] } });
        }
        captchaElement.current.reset();
      }
    }

    setLoading(false);
  }
  const handleCaptcha = (e) => {
    captcha.current=e;
  }
  return (
    <div className='display-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
      <form onSubmit={doReset} className='c-xs-90 c-sm-90 c-md-40 c-lg-40 c-xl-30 border-rad-5 p2' style={{ boxShadow: "1px 1px 10px 3px" }}>
        <div className='display-flex w-100 justify-content-center'>
          <img src={BigLogo} style={{ width: '250px' }} />
        </div>
        <div className='display-flex justify-content-center'>
          <div className='mt2 mb2 textcolor-black' style={{ fontWeight: 'bold', fontSize: '24pt' }}>Reset Password</div>
        </div>

        <PasswordInput
          Ref={password}
          label="Password"
          errorMessage={errorMessage.password}
          placeholder="password" />
        <PasswordInput
          Ref={confirm}
          label="Konfirmasi Password"
          errorMessage={errorMessage.confirm}
          placeholder="konfirmasi password" />
        <ReCAPTCHA
          ref={captchaElement}
          sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITEKEY}
          onChange={handleCaptcha}
        />
        {errorMessage.captcha && <div className='display-flex justify-content-center mt4 mb4'>
          <div className='textcolor-danger'>Recaptcha error</div>
        </div>}
        <div className='display-flex justify-content-center mt4 mb4'>
          <button disabled={loading?true:false} className='btn display-flex justify-content-center align-items-center btn-primary textcolor-black border-rad-10 w-100'>
          {loading && <Oval
            height={20}
            width={20}
            color="#FFFFFF"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#FFFFFF"
            strokeWidth={2}
            strokeWidthSecondary={2}

          />}
          Ubah Password</button>
        </div>
        <div className='display-flex justify-content-center mb4'>
          <Link to="/" className='w-100'>
            <button className='display-flex justify-content-center align-items-center btn btn-o-secondary textcolor-black border-rad-10 w-100'>
            <FontAwesomeIcon style={{marginRight:'10px'}} icon={faArrowLeft}></FontAwesomeIcon>Return to sign</button>
          </Link>
        </div>
      </form>
    </div>


  )
}