import { faClipboard, faSadCry, faFrown, faMeh, faSmile, faLaugh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function({rating}){
  return(
    <div>
      <div className="display-flex w-100 justify-content-center" style={{fontSize:'24pt'}}>
        {rating>4
          ?
          <FontAwesomeIcon icon={faLaugh}/>
          :
          <>
            {rating > 3 ?
             <FontAwesomeIcon icon={faSmile} />
             :
             <>
                {rating > 2 ?
                  <FontAwesomeIcon icon={faMeh} />
                  :
                  <>
                    {rating > 1 ?
                    <FontAwesomeIcon icon={faFrown} />
                    :
                    <>
                      {rating > 0 ?
                        <FontAwesomeIcon icon={faSadCry} />
                        :
                        <span style={{fontSize:'12pt'}}>-</span>
                      }
                    </>

                    }
                  </>
                }
             </>
            }
          </>
        }
      </div>
      <div className="display-flex w-100 justify-content-center">{rating}</div>
    </div>
  )
}