import { AdminRoutes } from "../../../Routes";
import { Link, Route, Routes } from "react-router-dom";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft,faUserTie,  faHome, faNewspaper, faPrint, faUser, faUserCheck, faWrench } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { HistoryItem, MenuItem } from "../../../Components/index";
export default ({ userData, setShowModal, history, page }) => {
  const frameRef = useRef(null);
  const menuRef = useRef(null);
  const minimize = useRef(false);
  const userSettingRef = useRef(null);
  const pageTitleRef = useRef(null);
  const toggleMinimizeRef = useRef(false);
  const toggleMinimize = () => {
    minimize.current = !minimize.current;
    toggleMinimizeRef.current.style.transform = minimize.current ? 'rotateZ(180deg)' : 'rotateZ(0deg)';
    menuRef.current.style.height = minimize.current ? '600px' : '500px';
    frameRef.current.style.height = minimize.current ? '600px' : '500px';
    if (minimize.current) {
      let menuItemList = Array.from(menuRef.current.querySelectorAll('.menu-label'));
      menuItemList.forEach((item, index) => {
        item.classList.add('display-none');
      });
      frameRef.current.classList.remove("c-lg-75");
      frameRef.current.classList.remove("c-xl-75");
      frameRef.current.classList.add("c-lg-85");
      frameRef.current.classList.add("c-xl-90");

      menuRef.current.classList.remove("c-lg-20");
      menuRef.current.classList.remove("c-xl-20");
      menuRef.current.classList.add("c-lg-8");
      menuRef.current.classList.add("c-xl-6");

      pageTitleRef.current.classList.remove("c-lg-75");
      pageTitleRef.current.classList.remove("c-xl-75");
      pageTitleRef.current.classList.add("c-lg-85");
      pageTitleRef.current.classList.add("c-xl-90");

      userSettingRef.current.classList.remove("c-lg-20");
      userSettingRef.current.classList.remove("c-xl-20");
      userSettingRef.current.classList.add("c-lg-8");
      userSettingRef.current.classList.add("c-xl-6");

      document.querySelector('.username-label').classList.add('display-none');
      document.querySelector('.user-control').classList.add('display-none');
    } else {
      let menuItemList = Array.from(menuRef.current.querySelectorAll('.menu-label'));
      menuItemList.forEach((item, index) => {
        item.classList.remove('display-none');
      });
      frameRef.current.classList.add("c-lg-75");
      frameRef.current.classList.add("c-xl-75");
      frameRef.current.classList.remove("c-lg-85");
      frameRef.current.classList.remove("c-xl-90");

      menuRef.current.classList.add("c-lg-20");
      menuRef.current.classList.add("c-xl-20");
      menuRef.current.classList.remove("c-lg-8");
      menuRef.current.classList.remove("c-xl-6");

      pageTitleRef.current.classList.add("c-lg-75");
      pageTitleRef.current.classList.add("c-xl-75");
      pageTitleRef.current.classList.remove("c-lg-85");
      pageTitleRef.current.classList.remove("c-xl-90");

      userSettingRef.current.classList.add("c-lg-20");
      userSettingRef.current.classList.add("c-xl-20");
      userSettingRef.current.classList.remove("c-lg-8");
      userSettingRef.current.classList.remove("c-xl-6");
      document.querySelector('.username-label').classList.remove('display-none');
      document.querySelector('.user-control').classList.remove('display-none');
    }

  }
  return (
    <>
      {/* <div className="pos-fixed display-flex justify-content-center align-items-center bg-white border-rad-10" style={{ bottom: '20px', right: '20px', width: '50px', height: '50px', boxShadow:'1px 1px 10px 3px',cursor:'pointer'}}>
        <span className="pos-absolute" style={{color:'white',fontSize:'10pt'}}>69</span>
        <div className="animated-alert border-solid-secondary-1 border-rad-10 pos-absolute" style={{
          bottom: '-10px',right: '0px',fontSize: '15pt',background: 'white'}}>!</div>
        <FontAwesomeIcon icon={faComment} size="2x"/>
      </div> */}
      <div className="p2">
        <div className="row justify-content-space-between">
          <div ref={userSettingRef} style={{ boxShadow: "1px 1px 10px 3px" }} className="border-rad-5 m2 p1 c-lg-20 c-xl-20">
            <div className="row justify-content-space-arround align-items-center">
              <img src={userData.foto} style={{ width: '50px', height: '50px', borderRadius: '50px' }} />
              <span className="textcolor-black username-label" style={{ fontSize: '16pt' }}>{userData.nama}</span>
            </div>
            <div className="row mt2 justify-content-space-arround align-items-center user-control">
              <Link to="/Personal" >
                <button type="button" className="btn-primary m1 p2 border-rad-10">
                  <span className="textcolor-black">User Setting</span>
                </button>
              </Link>
              <button type="button" onClick={() => { setShowModal(true) }} className="btn-danger m1 p2 border-rad-10">
                <span className="textcolor-black">Logout</span>
              </button>
            </div>
          </div>
          <div ref={pageTitleRef} style={{ boxShadow: "1px 1px 10px 3px" }} className="border-rad-5 m2 p1 c-lg-75 c-xl-75 display-flex align-items-center pl2">
            <button style={{ transition: '.2s', transform: 'rotateZ(0deg)' }} onClick={() => { toggleMinimize() }} className="btn btn-o-secondary mr4" ref={toggleMinimizeRef}>
              <FontAwesomeIcon icon={faCaretLeft} size="2x" />
            </button>
            <h1 className="textcolor-black">{page}</h1>
          </div>
        </div>
        <div className="row justify-content-space-between">
          <div ref={menuRef} style={{ boxShadow: "1px 1px 10px 3px", height: '500px', overflow: 'auto', transition: '.2s' }} className="border-rad-5 m2 p1 c-lg-20 c-xl-20">
            <MenuItem selected={page == "Home"} to="/" icon={faHome} label="Home" />
            <MenuItem selected={page == "Tickets"} to="Tickets" icon={faClipboard} label="Tickets" />
            <MenuItem selected={page == "News"} to="News" icon={faNewspaper} label="News" />
            <MenuItem selected={page == "Data Admin"} to="Admin" icon={faUserTie} label="Data Admin" />
            <MenuItem selected={page == "Data Topik Masalah"} to="TopikMasalah" icon={faClipboard} label="Data Topik Masalah" />
            <MenuItem selected={page == "Data Teknisi"} to="Teknisi" icon={faWrench} label="Data Teknisi" />
            <MenuItem selected={page == "Data Customer"} to="Customer" icon={faUserCheck} label="Data Customer" />
            <MenuItem selected={page == "Cetak Laporan"} to="CetakLaporan" icon={faPrint} label="Cetak Laporan" />
            <MenuItem selected={page == "Data User"} to="User" icon={faUser} label="Data User" />
            {history.length > 0 && <><div className="row mt-4">
              <div className="w-100 display-flex border-solid-bottom-black-1 textcolor-black p2 m2">
                <label className="menu-label">History</label>
              </div>
            </div>
              {history.map((item, index) => (
                <HistoryItem key={index} item={item} selected={page == item.id} />
              ))}

            </>}
          </div>
          <div ref={frameRef} style={{ boxShadow: "1px 1px 10px 3px", height: '500px', overflow: 'auto', transition: '.2s' }} className="border-rad-5 m2 p1 c-lg-75 c-xl-75">
            <Routes>
              {
                AdminRoutes.map((item, index) => (
                  <Route
                    key={index}
                    path={item.path}
                    element={item.element}
                  ></Route>
                ))
              }
            </Routes>
          </div>
        </div>
      </div>
    </>
  )
}
