import { useEffect, useState, useRef } from "react";
import { Oval } from "react-loader-spinner";
import { put, get } from "../../../../Libraries/Fetch";
import { useSelector } from "react-redux";
import { CKEditorElement, SelectInput, Textinput, ImgUpload, PasswordInput } from "../../../../Components";
import { faClipboard, faEnvelope, faLock, faMapMarker, faPhone, faUserCircle, faVenusMars } from "@fortawesome/free-solid-svg-icons";
export default ({ showModal, setShowModal, Ref, saveCallback }) => {
  const token = useSelector(state => state.session.token);
  const [loading, setLoading] = useState(false);
  const initialErrorMessage = {
    username: '',
    nama: '',
    alamat: '',
    telp: '',
    jekel: '',
    email: '',
    password: '',
    konfirmasi: '',
  }
  let [errorMessage, setErrorMessage] = useState(initialErrorMessage);
  let [alamat, setalamat] = useState([]);
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    let form = new FormData();
    form.append("password", Ref.password.current.value);
    form.append("konfirmasi", Ref.konfirmasi.current.value);


    let req = await put('Admin/Teknisi/editPassword/'+Ref.id_user.current, form, token);
    if (req.data.status == "success") {
      setLoading(false);
      alert(req.data.message)
      setShowModal(false);
      saveCallback();

    }
    else if (req.data.status == "error") {
      setLoading(false);
      alert(req.data.message);
      if (req.data.data.password) {
        setErrorMessage((n) => { return { ...n, password: req.data.data.password[0] } });
      }
      if (req.data.data.konfirmasi) {
        setErrorMessage((n) => { return { ...n, konfirmasi: req.data.data.konfirmasi[0] } });
      }
    }
  }
  return (
    <div className={showModal ? "modal active" : "modal"}>
      <div className='modal-container'>
        <div className='modal-content'>
          <button onClick={() => { setShowModal(false) }} className='pos-absolute btn-danger' style={{ width: '25px', height: '32px', textAlign: 'center', top: '-10px', right: '-10px' }}>
            X
          </button>
          <div className="modal-header">
            <h1>EDIT PASSWORD</h1>
          </div>
          <form onSubmit={handleSubmit} className='modal-body'>

            <PasswordInput Ref={Ref.password} label="Password" placeholder="password"
              icon={faLock} errorMessage={errorMessage.password} />
            <PasswordInput Ref={Ref.konfirmasi} label="Konfirmasi Password" placeholder="konfirmasi password"
              icon={faLock} errorMessage={errorMessage.konfirmasi} />
            <div className="w-100 dislay-flex mt2">
              <button disabled={false} className="btn-o-primary align-items-center border-rad-5 display-flex textcolor-black p2 m1">
                {loading && <Oval height={25} width={25} />}
                Simpan
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}