export default ({ status,justify }) => {
    let StatusR = "";
    let Class = "";
    let ContainerClass = "display-flex justify-content-"+justify;
    if (status == "NA") {
      Class = "bg-dark-purple border-solid--light-purple-1";
      StatusR = "Not Assigned";
    }
    else if (status == "ACC") {
      Class = "bg-dark-secondary border-solid--light-secondary-1";
      StatusR = "Awaiting Complete Confirmation";
    }
    else if (status == "CA") {
      Class = "bg-dark-danger border-solid--light-danger-1";
      StatusR = "Canceled By Admin";
    }
    else if (status == "A") {
      Class = "bg-dark-warning border-solid--light-warning-1";
      StatusR = "Assigned";
    }
    else if (status == "IP") {
      Class = "bg-dark-success border-solid--light-success-1";
      StatusR = "In Progress";
    }
    else if (status == "C") {
      Class = "bg-dark-primary border-solid--light-primary-1";
      StatusR = "Complete";
    }
    else if (status == "F") {
      Class = "bg-dark-danger border-solid--light-danger-1";
      StatusR = "Failed";
    }
    return (
      <div
      className={ContainerClass}>
        <div style={{ width: '80px', fontSize: '10pt' }} className={"textcolor-white border-rad-5 pl2 pr2 text-center " + Class}>
          {StatusR}
        </div>
      </div>
    )
  }