import Home from "../Pages/Customer/Home";
import MyTicket from "../Pages/Customer/MyTicket";
import Personal from "../Pages/Customer/Personal";
import Progress from "../Pages/Customer/Progress";
const route = [
  {
    'path': '/',
    'element': <Home />
  },
  {
    'path': '/myTicket',
    'element': <MyTicket />
  },
  {
    'path': '/Personal',
    'element': <Personal />
  },
  {
    'path': '/Progress/:id_tiket',
    'element': <Progress/>
  }
];
export default route;