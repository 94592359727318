import { useEffect,useState,useRef } from "react";
import {get} from "../../../Libraries/Fetch";
import { useDispatch,useSelector } from "react-redux";
import { Oval } from "react-loader-spinner";
import Options from "./Components/Options";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import ModalEditData from "./Components/ModalEditData";
import ModalEditPassword from "./Components/ModalEditPassword";
import ModalDetail from "./Components/ModalDetail";
import ModalDelete from "./Components/ModalDelete";
import { setPageName } from "../../../store";
import { SelectInput,Textinput } from "../../../Components";
export default function App() {
  const token=useSelector(state=>state.session.token);
  const dispatch=useDispatch();
  const [showModalEditData, setShowModalEditData] = useState(false);
  const [showModalEditPassword, setShowModalEditPassword] = useState(false);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [loading,setLoading]=useState(true);
  const [pageComponent,setPageComponent]=useState([])
  const [data,setData]=useState({
    data:[],
    maxPage:1
  });
  const ModalData={
    tableData:{
      limit:useRef(10),
      search:useRef(null)
    },
    editData: {
      id_user: useRef(null),
      username: useRef(null),
      nama: useRef(null),
      alamat: useRef(null),
      telp: useRef(null),
      jekel: useRef(null),
      email: useRef(null),
      foto: useRef(null),
      oldFoto: useRef(""),
    },
    delete:{
      id_user:useRef(null)
    },
    editPassword: {
      id_user: useRef(""),
      password: useRef(null),
      konfirmasi: useRef(null),
    },
    detail:{
      username: useRef(null),
      nama: useRef(null),
      alamat: useRef(null),
      telp: useRef(null),
      jekel: useRef(null),
      email: useRef(null),
      foto: useRef("")
    }
  }
  const page=useRef(1);
  const readData=async()=>{
    setLoading(true);
    let q = ModalData.tableData.search.current.value;
    let req="";
    if(q!=""){
      req = await get("Admin/Customer/search/" + page.current + "/" + ModalData.tableData.limit.current + "?q=" + q, token);
    }
    else{
      req = await get("Admin/Customer/" + page.current + "/" + ModalData.tableData.limit.current, token);
    }

    setData(req.data.data);
    setLoading(false);
    setPageComponent((n) => {
      let objek = {
        minPage: 1,
        maxPage: 1,
      };
      let pageComponentBaru=[];
      objek.minPage = page.current - 2;
      if (objek.minPage <= 0) {
        objek.minPage = 1;
      }
      if(page.current==1){
        objek.maxPage = page.current + 4;
      }
      else if(page.current == 2) {
        objek.maxPage = page.current + 3;
      }
      else if (page.current >2) {
        objek.maxPage = page.current + 2;
      }
      if (objek.maxPage > req.data.data.maxPage) {
        objek.maxPage = req.data.data.maxPage;
      }
      for (let i = objek.minPage; i <= objek.maxPage; i++) {
        if(i==page.current){
          pageComponentBaru.push(
            <button key={i} className="btn-success border-solid-success-1 border-rad-5 m1">
              {i}
            </button>
          )
        }
        else{
          pageComponentBaru.push(
            <button key={i} onClick={async()=>{page.current=i;await readData()}} className="btn-o-success border-solid-success-1 border-rad-5 m1">
              {i}
            </button>
          )
        }
      }
      return pageComponentBaru;
    })
    setTimeout(()=>{
      console.log(pageComponent);
    },1000);
  }
  const readOne = async (id_user) => {
    let req = await get("Admin/Customer/readOne/" + id_user, token);
    ModalData.editData.id_user.current = id_user;
    ModalData.editData.username.current.value = req.data.data[0].user.username;
    ModalData.editData.nama.current.value = req.data.data[0].nama;
    ModalData.editData.jekel.current.value = req.data.data[0].user.jekel;
    ModalData.editData.oldFoto.current = req.data.data[0]._foto;
    ModalData.editData.email.current.value = req.data.data[0].user.email;
    ModalData.editData.alamat.current.value = req.data.data[0].alamat;
    ModalData.editData.telp.current.value = req.data.data[0].telp;
    setShowModalEditData(true);
  }
  const readDetail = async (id_user) => {
    let req = await get("Admin/Customer/readOne/" + id_user, token);
    ModalData.detail.username.current.value = req.data.data[0].user.username;
    ModalData.detail.nama.current.value = req.data.data[0].nama;
    ModalData.detail.foto.current = req.data.data[0]._foto;
    ModalData.detail.jekel.current.value = req.data.data[0].user.jekel;
    ModalData.detail.email.current.value = req.data.data[0].user.email;
    ModalData.detail.alamat.current.value = req.data.data[0].alamat;
    ModalData.detail.telp.current.value = req.data.data[0].telp;
    setShowModalDetail(true);
  }
  const setDelete = (id_user) => {
    ModalData.delete.id_user.current = id_user;
    setShowModalDelete(true);
  }
  const handleEditPassword = (id_user) => {
    ModalData.editPassword.id_user.current = id_user;
    setShowModalEditPassword(true);
  }
  useEffect(()=>{
    readData();
    dispatch(setPageName("Data Customer"));
  },[])
  return (
    <div className="m4">
      <ModalDetail
        showModal={showModalDetail}
        setShowModal={setShowModalDetail}
        Ref={ModalData.detail}
      />
      <ModalEditData
        showModal={showModalEditData}
        setShowModal={setShowModalEditData}
        Ref={ModalData.editData}
        saveCallback={() => {
          readData();
        }}
      />
      <ModalEditPassword
        showModal={showModalEditPassword}
        setShowModal={setShowModalEditPassword}
        Ref={ModalData.editPassword}
        saveCallback={() => {
          readData();
        }}
      />
      <ModalDelete
        showModal={showModalDelete}
        setShowModal={setShowModalDelete}
        Ref={ModalData.delete}
        saveCallback={() => {
          readData();
        }}
      />
      <>
        <div className="row w-100 mt2 justify-content-space-between align-items-center">
          <div className="c-xs-100 c-sm-100 c-md-100 c-lg-40">
            <div className="display-flex align-items-center">
              <span>Show</span>
              <SelectInput
                onChange={async (e) => {
                  page.current = 1;
                  ModalData.tableData.limit.current = e.target.value;
                  await readData();
                }}
                className="display-block ml2 mr2"
                style={{ width: "45px" }}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </SelectInput>
              <span>Item</span>
            </div>
          </div>
          <div className="c-xs-100 c-sm-100 c-md-100 c-lg-40">
            <Textinput placeholder="search"
              Ref={ModalData.tableData.search}
              onKeyDown={async (e) => {
                let q = ModalData.tableData.search.value
                if (e.key == "Enter") {
                  page.current = 1;
                  await readData();
                }
              }}
              icon={faSearch} />
          </div>
        </div>
        {
          loading
            ?
            <div className="row justify-content-center mt2">
              <Oval color="#007bff" height={50} width={50} />
            </div>
            :
            <div className="row mt2">
              <div className="w-100 mt2 table-container">
                <table className="table">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Username</th>
                      <th>Nama Lengkap</th>
                      <th>Email</th>
                      <th>Jenis Kelamin</th>
                      <th>Alamat</th>
                      <th>Nomor Telp.</th>
                      <th>Jumlah Tiket</th>
                      <th>Status Verifikasi</th>
                      <th>Control</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.data.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.user.username}</td>
                            <td>
                              {item.nama}
                            </td>
                            <td>
                              {item.user.email}
                            </td>
                            <td>
                              {item._jekel}
                            </td>
                            <td>
                              {item.alamat}
                            </td>
                            <td>
                              {item.telp}
                            </td>
                            <td>
                              {item._jml_tiket}
                            </td>
                            <td>
                              {item._verifikasi}
                            </td>
                            <td>
                              <Options
                                item={item}
                                readOne={readOne}
                                readDetail={readDetail}
                                handleEditPassword={handleEditPassword}
                                setDelete={setDelete}
                                deleteCallback={() => {
                                  page.current = 1;
                                  readData();
                                }}
                              />
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
        }
        <div className="display-flex justify-content-flex-end w-100">
          <button onClick={()=>{page.current=1;readData();}} className="btn-o-success border-solid-success-1 border-rad-5 m1">
            {"|<"}
          </button>
          <button onClick={() => { page.current -= 1; if (page.current <= 0) { page.current = 1 } readData(); }} className="btn-o-success border-solid-success-1 border-rad-5 m1">
            {"<<"}
          </button>
          {pageComponent}
          <button onClick={() => { page.current += 1; if (page.current > data.maxPage) { page.current = data.maxPage } readData(); }} className="btn-o-success border-solid-success-1 border-rad-5 m1">
            {">>"}
          </button>
          <button onClick={() => { page.current = data.maxPage;readData(); }} className="btn-o-success border-solid-success-1 border-rad-5 m1">
            {">|"}
          </button>
        </div>
      </>
    </div>
  )
}