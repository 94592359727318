import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircleExclamation, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Oval } from "react-loader-spinner";
import {get} from "../../../../Libraries/Fetch";
import { useSelector } from "react-redux";
export default function({item,ReadOne}){
  const [loading,setLoading]=useState(false);
  const token = useSelector(state => state.session.token);
  const readOne = async (id_proses,Ref) => {
    setLoading(true);
    await ReadOne();
    setLoading(false);
  }
  return (
    <div className="display-flex align-items-center border-solid-left-success-5 pt6 pos-relative">
      {
        item.status == "I"
          ?
          <div className="border-solid-success-1 border-rad-10 pos-absolute bg-white" style={{ width: '20px', height: '20px', textAlign: 'center', left: '-13px' }}>

          </div>
          :
          <div className="border-solid-success-1 border-rad-10 pos-absolute bg-success" style={{ width: '20px', height: '20px', textAlign: 'center', left: '-13px' }}>
            <FontAwesomeIcon icon={faCheck} className="textcolor-white" />
          </div>
      }
      <div className="w-100 ml4">
        <div className="textcolor-black">{item.topik}</div>
        <div className="textcolor-black mt2" style={{ fontWeight: 'bold' }}>{item._tgl_proses}</div>
      </div>
      <button onClick={()=>{readOne(item.id_proses)}} className="border-solid-secondary-1 border-rad-5 p1">
          {
            loading
            ?
            <Oval color="black" height={15} width={15} />
            :
            <FontAwesomeIcon icon={faCircleExclamation} />
          }
      </button>
    </div>
  )
}