import { useState, useEffect, useRef, forwardRef, useContext } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useParams } from 'react-router-dom';
import { Textinput } from '../../../Components';
import BigLogo from "../../../assets/img/logo round.png";
import ArrowLeft from "../../../assets/logo/arrow left.png";
import resetPassword from '../../../services/public/resetPassword';
import { Oval } from 'react-loader-spinner';
import { faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons';
export default function App() {
  let email = useRef(null);
  let params=useParams();
  const initialErrorMessage = {
    email: ''
  }
  const [loading, setLoading] = useState(false);
  const [countdown,setCountdown]=useState(59);
  let [errorMessage, setErrorMessage] = useState(initialErrorMessage);

  let captcha = useRef(null);
  let captchaElement = useRef(null);
  const handleReset = async (e) => {
    setLoading(true);
    setErrorMessage(initialErrorMessage)
    setLoading(true);
    e.preventDefault();
    let fr = new FormData();
    fr.append('email', email.current.value);
    fr.append('captcha', captcha.current);
    const req = await resetPassword(fr);
    if (req.data.status == "error") {
      if (req.data.message.email) {
        setErrorMessage((n) => { return { ...n, email: req.data.message.email[0] } });
      }
      if (req.data.message.captcha) {
        setErrorMessage((n) => { return { ...n, captcha: req.data.message.captcha[0] } });
      }
      captchaElement.current.reset();
    }
    else {
      alert(req.data.message);

      window.location.reload();
    }
    setLoading(false);
  }
  const handleCaptcha = (e) => {
    captcha.current = e;
  }
  useEffect(()=>{
    email.current=params.email;
    let countdownInterval=setInterval(()=>{
      setCountdown((n)=>{
        if(n>0){
          return n - 1;
        }
        else{
          return n;
        }
      });
    },1000);
  },[])
  return (
    <div className='display-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
      <form onSubmit={handleReset} className='c-xs-90 c-sm-90 c-md-40 c-lg-40 c-xl-30 border-rad-5 p2' style={{ boxShadow: "1px 1px 10px 3px"}}>
        <div className='display-flex align-items-center' style={{minHeight:'500px'}}>
          <div className='w-100'>
            <div className='display-flex w-100 justify-content-center'>
              <img src={BigLogo} style={{ width: '100px' }} />
            </div>
            <div className='display-flex justify-content-center text-center' style={{ fontSize: '10pt' }}>
              <span>We have send recovery email to your mailbox</span>
            </div>

            {countdown==0 && <ReCAPTCHA
              ref={captchaElement}
              sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITEKEY}
              onChange={handleCaptcha}
            />}
            {errorMessage.captcha && <div className='display-flex justify-content-center mt4 mb4'>
              <div className='textcolor-danger'>Recaptcha error</div>
            </div>}
            <div className='display-flex justify-content-center mt4 mb4'>
              {countdown==0 ?
              <button disabled={loading ? true : false} className='btn display-flex justify-content-center align-items-center btn-primary textcolor-black border-rad-10 w-100'>
                {loading && <Oval
                  height={20}
                  width={20}
                  color="#FFFFFF"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel='oval-loading'
                  secondaryColor="#FFFFFF"
                  strokeWidth={2}
                  strokeWidthSecondary={2}

                />}
                Resent</button>
              :
                <button disabled={true} className='btn display-flex justify-content-center align-items-center btn-primary textcolor-black border-rad-10 w-100'>
                  Resent {countdown}</button>
              }
            </div>
            <div className='display-flex justify-content-center mb4'>
              <Link to="/" className='w-100'>
                <button className='display-flex justify-content-center align-items-center btn btn-o-secondary textcolor-black border-rad-10 w-100'><img src={ArrowLeft} />Return to sign</button>
              </Link>
            </div>
          </div>
        </div>
      </form>
    </div>


  )
}