import { PublicRoutes } from "../../Routes";
import { NavLink, Route, Router, Routes, redirect, useLocation } from "react-router-dom";
export default () => {
  return (
    <>
      <Routes>
        {
          PublicRoutes.map((item, index) => (
            <Route
              key={index}
              path={item.path}
              element={item.element}
            ></Route>
          ))
        }
      </Routes>
    </>
  )
}