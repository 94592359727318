import { useState } from "react";
import { Oval } from "react-loader-spinner";
import { del } from "../../../../Libraries/Fetch";
import { useSelector,useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFrown,faCommentDots,faCheck,faXmark,faPencilSquare,faTrash,faHistory,faCircleQuestion, faX } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { setHistory } from "../../../../store";
export default ({
  item,
  handleDetail,
  setConfirm,
  readFeedback,
  showComplain,
  handleFailed
}) => {
  const nav=useNavigate();
  const dispatch=useDispatch();
  let token = useSelector(state => state.session.token);
  const [loadingFeedback, setLoadingFeedback] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  return (
    <div className="row justify-content-center">
      {item.status!="NA" &&
          <button
            style={{ width: '25px' }}
            onClick={() => {
              nav("/Complain/" + item.id_tugas)
            }}
            type="button" className="m1 btn-o-danger textcolor-black border-rad-5">
            <FontAwesomeIcon icon={faFrown} />
          </button>

      }

      {item._status == "C" &&
        <button
          style={{ width: '25px' }}
          onClick={async () => {
            setLoadingFeedback(true);
            await readFeedback(item.id_tugas);
            setLoadingFeedback(false)
          }} type="button" className="m1 btn-o-secondary textcolor-black border-rad-5 display-flex align-items-center">
          {loadingFeedback ?
            <Oval color="black" height={15} width={15} />
            :
            <FontAwesomeIcon icon={faCommentDots} />
          }
        </button>}
      <>
        <button style={{ width: '25px' }}
         onClick={async () => {
          nav("/Progress/" + item.id_tugas)
          dispatch(setHistory({ id: item.id_tugas, label: item.infrastruktur.topik_masalah.nama }));
        }} type="button" className="m1 btn-o-secondary textcolor-black border-rad-5 display-flex align-items-center">
          <FontAwesomeIcon icon={faHistory} />

        </button>
        <button
          style={{width:'25px'}}
          onClick={() => {
            handleDetail(item.id_tugas);
          }}
          type="button" className="m1 btn-o-secondary textcolor-black border-rad-5">
          {loadingDetail ?
            <Oval color="black" height={15} width={15} />
            :
            <FontAwesomeIcon icon={faCircleQuestion} />
          }
        </button>
      </>
      {(item._status != "ACC" && item._status != "CA" &&item._status != "C" && item._status != "F") &&
        <div className="row justify-content-center">
          <button
            style={{ width: '25px' }}
            onClick={async () => {
              setConfirm(item.id_tugas);
            }} type="button" className="m1 btn-o-secondary textcolor-black border-rad-5 display-flex align-items-center">
            <FontAwesomeIcon icon={faCheck} />

          </button>
          <button
            style={{ width: '25px' }}
            onClick={async () => {
              handleFailed(item.id_tugas);
            }} type="button" className="display-flex justify-content-center m1 btn-o-danger textcolor-black border-rad-5 display-flex align-items-center">
            <FontAwesomeIcon icon={faX} />

          </button>
        </div>}
    </div>
  )
}