import { useEffect, useState, useRef } from "react";
import { Oval } from "react-loader-spinner";
import { del, get } from "../../../../Libraries/Fetch";
import { useSelector } from "react-redux";
import { CKEditorElement, SelectInput, Textinput, ImgMultiUpload } from "../../../../Components";
import { faCheck, faClipboard, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default ({ showModal, setShowModal, Ref, saveCallback }) => {
  const token = useSelector(state => state.session.token);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    let form = new FormData();
    let req = await del('Admin/Teknisi/' + Ref.id_user.current, null, token);
    if (req.data.status == "success") {
      setLoading(false);
      alert(req.data.message)
      setShowModal(false);
      saveCallback();

    }
    else if (req.data.status == "error") {
      setLoading(false);
      alert(req.data.message);
    }
  }
  return (
    <div className={showModal ? "modal active" : "modal"}>
      <div className='modal-container'>
        <div className='modal-content' style={{ width: '400px' }}>
          <button onClick={() => { setShowModal(false) }} className='pos-absolute btn-danger' style={{ width: '25px', height: '32px', textAlign: 'center', top: '-10px', right: '-10px' }}>
            X
          </button>
          <div className="modal-header">
            <h1>HAPUS TEKNISI</h1>
          </div>
          <form onSubmit={handleSubmit} className='modal-body'>
            <div className="w-100 dislay-flex mt2 justify-content-center">
              Hapus Teknisi Ini ?
            </div>
            <div className="w-100 display-flex mt2">
              <button disabled={false} className="btn-primary align-items-center border-rad-5 display-flex textcolor-black p2 m1">
{loading ? <Oval height={15} width={15} />
:               <FontAwesomeIcon icon={faCheck} />}
              </button>
              <button disabled={false} type="button" onClick={() => { setShowModal(false) }} className="btn-danger align-items-center border-rad-5 display-flex textcolor-black p2 m1">
                <FontAwesomeIcon icon={faX} />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}