import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
export default ({ label, selected, item }) => {
  let Class = "w-100 display-flex border-solid-black-1 textcolor-black p2 m2 border-rad-10";
  if (selected) Class += " bg-primary border-solid-primary-1";
  return (
    <Link to={"Progress/" + item.id} className="row w-100">
      <div className={Class}>
        <div style={{ width: '30px' }}><FontAwesomeIcon icon={faClipboard} /></div>
        <span className="textcolor-black menu-label">{item.label}</span>
      </div>
    </Link>
  )
}