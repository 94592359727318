import { useEffect, useState, useRef } from "react";
import { Oval } from "react-loader-spinner";
import { del, put, get } from "../../../../Libraries/Fetch";
import { useSelector } from "react-redux";
import { CKEditorElement, SelectInput, Textinput, ImgMultiUpload } from "../../../../Components";
import { faClipboard,faSadCry,faFrown,faMeh,faSmile,faLaugh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default ({ showModal, setShowModal, Ref, saveCallback }) => {
  const token = useSelector(state => state.session.token);
  const ElemenRating = [{ icon: faSadCry, label: "Sangat Buruk" },
  { icon: faFrown, label: "Buruk" },
  { icon: faMeh, label: "Lumayan" },
  { icon: faSmile, label: "Baik" },
  { icon: faLaugh, label: "Memuaskan" }];
  const [loading, setLoading] = useState(false);
  const initialErrorMessage = {
    ket: ''
  }
  let [errorMessage, setErrorMessage] = useState(initialErrorMessage);
  let [rating, setRating] = useState(0);
  let [prevList, setPrevList] = useState(Ref.foto.current);
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    let form = new FormData();
    form.append("rating", rating);
    form.append("ket", Ref.ket.current.data.get());
    Ref.foto.current.forEach((item, index) => {
      form.append("foto[]", item);
    })
    let req = await put('Customer/Tiket/feedback/' + Ref.id_tiket.current, form, token);
    if (req.data.status == "success") {
      setLoading(false);
      alert(req.data.message)
      setShowModal(false);
      saveCallback();
      setPrevList([]);
      Ref.foto.current = [];
    }
    else if (req.data.status == "error") {
      setLoading(false);
      alert(req.data.message);
      if (req.data.data.ket) {
        setErrorMessage((n) => { return { ...n, ket: req.data.data.ket[0] } });
      }
    }
  }
  useEffect(() => {
    setPrevList(Ref.oldFoto.current);

  }, [showModal])
  return (
    <div className={showModal ? "modal active" : "modal"}>
      <div className='modal-container'>
        <div className='modal-content'>
          <button onClick={() => { setShowModal(false) }} className='pos-absolute btn-danger' style={{ width: '25px', height: '32px', textAlign: 'center', top: '-10px', right: '-10px' }}>
            X
          </button>
          <div className="modal-header">
            <h1>Send Feedback</h1>
          </div>
          <form onSubmit={handleSubmit} className='modal-body'>
            <CKEditorElement
              label="Keterangan"
              callBack={(editor) => { Ref.ket.current = editor }} />
            <div className="w-100 mt2 display-flex">
              <label>Indeks Kepuasan</label>
            </div>
            <div className="w-100 mt2 display-flex">
              {ElemenRating.map((item,index)=>(
                <div className="display-block text-center">
                  <button key={index} type="button" className={Ref.rating.current == index ? "btn-success m2 p1" : "btn-o-success m2 p1"} onClick={() => { setRating(index) }} style={{ fontSize: '24pt' }}>
                    <FontAwesomeIcon icon={item.icon} />
                  </button>
                <label className="display-block">{item.label}</label>
                </div>

              ))
              }
            </div>
            <div className="w-100 mt2">
              <ImgMultiUpload
                name="gambar_f"
                prevList={prevList}
                keyToDisplay="_foto"
                closeCallback={async (index) => {
                  if (Ref.oldFoto.current[index] != undefined) {
                    await del("Customer/Tiket/deleteGambarFeedback/" + Ref.oldFoto.current[index].id_gambar_feedback, null, token);
                    Ref.oldFoto.current = Ref.oldFoto.current.filter((item) => Ref.oldFoto.current.indexOf(item) != index)
                  }
                  Ref.foto.current = Ref.foto.current.filter((item) => Ref.foto.current.indexOf(item) != index)
                  setPrevList((n) => {
                    let nilaiLama = n;
                    return n.filter((item) => n.indexOf(item) != index);
                  })
                }}
                addCallback={(file) => {
                  setPrevList((n) => {
                    return [...n, URL.createObjectURL(file)];
                  });
                  Ref.foto.current.push(file);

                }}
                label="Gambar" />
            </div>
            <div className="w-100 dislay-flex mt2">
              <button disabled={false} className="btn-o-primary align-items-center border-rad-5 display-flex textcolor-black p2 m1">
                {loading && <Oval height={25} width={25} />}
                Simpan
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}