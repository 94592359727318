import { ImgUpload } from "../../../../Components";
import { useState } from 'react';
export default function (props) {
  const [errorMessage, setErrorMessage] = useState(props.errorMessage);
  return (
    <div className='c-xs-100 c-sm-100 c-md-100 c-lg-45 c-xl-45 m2'>
      <ImgUpload name="foto-profil" label="Foto Profil" Ref={props.Ref.foto} />
      {errorMessage && <div className='display-flex justify-content-center mt4 mb4'>
        <div className='textcolor-danger'>{props.errorMessage}</div>
      </div>}
    </div>
  )
}