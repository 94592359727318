import { useEffect,useState,useRef } from "react";
import {get} from "../../../Libraries/Fetch";
import { useDispatch,useSelector } from "react-redux";
import { Oval } from "react-loader-spinner";
import Options from "./Components/Options";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircleExclamation, faPlus } from "@fortawesome/free-solid-svg-icons";
import ModalProgress from "./Components/ModalProgress";
import StatusCard from "./Components/StatusCard";
import JobDetail from "./Components/JobDetail";
import { useParams } from "react-router-dom";
import { setPageName } from "../../../store";
export default function App() {
  const token=useSelector(state=>state.session.token);
  const dispatch=useDispatch();
  const [showModalProgress,setShowModalProgress]=useState(false);
  const [loading,setLoading]=useState(true);
  const {id_tiket}=useParams();
  const [data,setData]=useState({
    data:[]
  });
  const [progress, setProgress] = useState({
    data: []
  });
  const ModalData={
    progress:{
      tgl:useRef(null),
      ket:useRef(null),
      foto:useRef([])
    }
  }
  const readAll = async () => {
    setLoading(true);
    let reqStatus = await get("Customer/Progress/" + id_tiket, token);
    let reqProgress = await get("Customer/Progress/readProgress/" + id_tiket, token);
    setData(reqStatus.data);
    setProgress(reqProgress.data)
    setLoading(false)
  }
  const readOne=async(id_proses)=>{
    let req = await get("Customer/Progress/readOne/" + id_proses, token);
    ModalData.progress.tgl.current = req.data.data._tgl_proses;
    ModalData.progress.ket.current = req.data.data.ket;
    ModalData.progress.foto.current = req.data.data._foto;
    setShowModalProgress(true);
  }
  useEffect(()=>{
    dispatch(setPageName('Progress Tracking'))
    readAll();
  },[id_tiket])
  return (
    <div className="m4">
      <ModalProgress
        showModal={showModalProgress}
        setShowModal={setShowModalProgress}
        Ref={ModalData.progress}
      />
      <>
        {
          loading
            ?
            <div className="row justify-content-center mt2">
              <Oval color="#007bff" height={50} width={50} />
            </div>
            :
            <div className="row mt2 justify-content-space-between">
              <JobDetail data={data.data[0]}/>
              <div className="c-xs-100 c-sm-100 c-md-100 c-lg-55 c-xl-65 border-solid-primary-1 p3 border-rad-5">
                {
                  progress.data.length>0
                  ?
                  <>
                    {progress.data.map((item, index) => (
                      <StatusCard
                       Ref={ModalData.progress}
                       ReadOne={async()=>{await readOne(item.id_proses)}}
                       item={item}
                       key={index} />
                    ))
                    }
                  </>
                  :
                  <div className="display-flex w-100 h-100 align-items-center justify-content-center">
                    <h4>
                      PROGRESS BELUM BERJALAN
                    </h4>
                  </div>

                }
              </div>
            </div>
        }
      </>
    </div>
  )
}