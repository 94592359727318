import { useEffect, useState,useRef } from "react";
import {download,post} from "../../../Libraries/Fetch";
import { useSelector } from "react-redux";
import {setUserData,setPageName} from "../../../store";
import { useDispatch } from "react-redux";
import { SelectInput } from "../../../Components";
import { faCalendar, faClockFour } from "@fortawesome/free-solid-svg-icons";
import { Oval } from "react-loader-spinner";
export default function App() {
  const dispatch = useDispatch();
  const token=useSelector(state=>state.session.token);
  const [loading,setLoading]=useState(false);
  const initialErrorMessage = {
    tahun: '',
    bulan: '',
  }
  let [errorMessage, setErrorMessage] = useState(initialErrorMessage);
  const formData = {
    bulan: useRef(null),
    tahun: useRef(null)
  }
  let bulan=["januari","Februari","Maret","April","Mei","Juni","Juli","Agustus","September","Oktober","November","Desember"];
  let tahun=[];
  let objekWaktu=new Date();
  for (let i = 2010; i <= objekWaktu.getFullYear(); i++) {
    tahun.push(i);
  }
  const doPrint = async (e) => {
    setErrorMessage(initialErrorMessage)
    setLoading(true);
    e.preventDefault();
    let fr = new FormData();
    fr.append('bulan', formData.bulan.current.value);
    fr.append('tahun', formData.tahun.current.value);
    const req = await download('Admin/CetakLaporan', fr,token);
    console.log(req);
    let aElement=document.createElement("a");
    aElement.setAttribute('download', "Laporan Bulan " + bulan[parseInt(formData.bulan.current.value) - 1] + " Tahun " + formData.tahun.current.value +".pdf");
    const href = URL.createObjectURL(req.data);
    aElement.href = href;
    aElement.setAttribute('target', '_blank');
    aElement.click();
    URL.revokeObjectURL(href);
    setLoading(false);
  }
  useEffect(()=>{
    dispatch(setPageName('Cetak Laporan'))
  },[])
  return (
    <div className="row m4">
      <form onSubmit={doPrint} className="display-block w-100">
        <SelectInput label="Bulan" Ref={formData.bulan} errorMessage={errorMessage.bulan} icon={faClockFour}>
          {bulan.map((item, index) => (
            <option key={index} value={index + 1}>{item}</option>
          ))}
        </SelectInput>
        <SelectInput label="Tahun" Ref={formData.tahun} icon={faCalendar} errorMessage={errorMessage.tahun}>
          {tahun.map((item, index) => (
            <option key={index} value={item}>{item}</option>
          ))}
        </SelectInput>
        <div className="w-100 mt2 display-flex">
          <button type="submit" className="btn-o-primary m1 p2 border-rad-10">
            {loading
            ?
            <Oval color="#ffffff" height="16" width="16" />
             :
             <span className="textcolor-black">Cetak</span>}
          </button>
        </div>
      </form>
    </div>

  )
}