import { useState, useEffect, useRef, forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import BigLogo from "../../../assets/img/logo round.png";
import Google from "../../../assets/logo/logout.png";
import { setLogout } from '../../../store';
export default function App() {
  const dispatch = useDispatch();
  return (
    <div className='display-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
      <form className='c-xs-90 c-sm-90 c-md-40 c-lg-40 c-xl-30 border-rad-5 p2' style={{ boxShadow: "1px 1px 10px 3px"}}>
        <div className='display-flex align-items-center' style={{ minHeight: '500px' }}>
          <div className="display-block w-100">
            <div className='display-flex w-100 justify-content-center'>
              <img src={BigLogo} style={{ width: '100px' }} />
            </div>
            <div className='display-flex justify-content-center'>
              <div className='mt2 mb2 textcolor-black' style={{ fontWeight: 'bold', fontSize: '24pt' }}>Please Verify</div>
            </div>
            <div className='display-flex justify-content-center text-center' style={{ fontSize: '10pt' }}>
              <span>Your account has not verified yet, please verify by opening the link that we sent to your email</span>
            </div>
            <div className='display-flex justify-content-center mb4'>
              <button onClick={()=>dispatch(setLogout())} className='display-flex justify-content-center align-items-center btn btn-o-danger textcolor-black border-rad-10 w-100'><img src={Google} />Logout</button>
            </div>
          </div>
        </div>
      </form>
    </div>


  )
}