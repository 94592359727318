import Login  from "../Pages/Public/Login"
import SignUp from "../Pages/Public/Signup"
import ResetPassword from "../Pages/Public/ResetPassword"
import ResetSend from "../Pages/Public/ResetSend";
import DoReset from "../Pages/Public/DoReset"
const route = [
  {
    'path': '/',
    'element': <Login />
  },
  {
    'path': '/sign-up',
    'element': <SignUp />
  }, {
    'path': '/reset',
    'element': <ResetPassword />
  }, {
    'path': '/resetSend/:email',
    'element': <ResetSend />
  }, {
    'path': '/doReset/:kd_reset',
    'element': <DoReset />
  },
  {
    'path': '/:token',
    'element': <Login />
  },
  {
    'path': '*',
    'element': <h2>Notfound</h2>
  }
];
export default route;