
import { Link} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default  ({ icon, label, to, onClick, selected }) => {
  let Class = "w-100 display-flex border-solid-black-1 textcolor-black p2 m2 border-rad-10";
  if (selected) Class += " bg-primary border-solid-primary-1";
  return (
    <Link to={to} onClick={onClick} className="row">
      <div className={Class}>
        <div style={{ width: '30px' }}><FontAwesomeIcon icon={icon} /></div>
        <span className="textcolor-black menu-label">{label}</span>
      </div>
    </Link>
  )
}